<script>
//Utils
import API from "/src/core/Api";
import {mapActions, mapGetters} from "vuex";

//Fabri js
import FreeDrawing from "/src/modules/medico/fabric/FreeDrawing.vue";

//Images
import Mammary from "/src/assets/Images/MammaryG.jpg";
import Fallopie from "/src/assets/Images/Fallopie.jpg"

//Box layout
import Box from '/src/modules/medico/views/fichas/Box.vue';

export default { 
 data() {
        return {
        //data
        Images:[Mammary,Fallopie],
        estado_paciente: {
            cardiopulmonar:{
                label: "Estado cardiopulmonar",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            tiroides:{
                label: "Estado de la glandula tiroides",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            mamas:{
                label: "Estado de la glandula mamaria o senos",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            abdomen:{
                label: "Estado del abdomen",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            vulva:{
                label: "Estado de la vulva (Exterior)",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            vagina:{
                label: "Estado de la vagina (Interior)",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            cervix:{
                label: "Estado del cervix",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            usg:{
                label: "USG Pelvico (Ultrasonido Pélvico)",
                value: "",
                placeholder: "Ingrese observaciones",
                type : "textarea"
            },
            utero:{
                label: "Estado del Utero",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            ovario_d:{
                label: "Observación del ovario derecho",
                value: "",
                placeholder: "Ingrese observaciones",
                type : "textarea"
            },
            ovario_i:{
                label: "Observación del ovario izquierdo",
                value: "",
                placeholder: "Ingrese observaciones",
                type : "textarea"
            },
            endometrio:{
                label: "Observación del endometrio",
                value: "",
                placeholder: "Ingrese observaciones",
                type : "textarea"
            },
          }
      }
},
 components:{
    FreeDrawing,
    Box,
 },
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
 mounted(){
  const self = this;

  if(self.GetMedicoFichaActive.metadata !== undefined) {
            if(self.GetMedicoFichaActive.metadata.estado_paciente){
                Object.keys(self.estado_paciente).map(function (value) {
                               self.estado_paciente[value].value = self.GetMedicoFichaActive.metadata.estado_paciente[value] || '';
                            });
            }
        }
 },
 methods:{
  saveGinec(slug){
      const self = this;
      let itemsTosave={};
      
      Object.keys(self[slug]).map(function (value) {
          itemsTosave[value]=self[slug][value].value;
      });
          
      API.send('POST', 'orders/metadata/save/', {
              orderId: self.GetMedicoFichaActive.orden.id,
              slug,
              detail: itemsTosave,
              uniqueMeta: 1,
          }, function (response) {
              API.showSuccessAlert(response.msg);
          },
          function (response) {
              API.showErrorAlert(response.msg);
          }); 
  },
 }
 }
</script>
 
 
<template>
   <box title="Estado de la paciente" icon="fas fa-notes-medical" :contraction='true'>
    <section class="row">
      <div v-for="item in estado_paciente" class="col-12 col-md-4">
                <div class="form-group mt-1">
                    <label>{{ item.label }}</label>
                    <textarea :type="item.type" class="form-control" :placeholder="item.placeholder" v-model="item.value" @change="saveGinec('estado_paciente')"></textarea>
                </div>
            </div>
  </section>
  </box>
  <box title="Herramienta dibujo" icon="fa-solid fa-palette">
    <section class="row">
    
      <div class="col-12 pintura">
        <FreeDrawing :Category="'G'" :Images="Images"></FreeDrawing>
      </div>
    </section>
  </box>
</template>
 
 
<style scoped>
 
 .pintura{
  width: 100%;
  height: fit-content;

 }
</style>