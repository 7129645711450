<template>
    <layout :active="2" :title="true">
        <div class="content-body orderPos">
            <div class="container">
                <div class="row" v-if="!showOrderDetailsCreation">
                    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card acc_balance">
                            <div class="card-body">
                                <!--<div class="btn-group mb-2">
                                    <button class="btn btn-outline-dark" @click="changeOrderType('directo')" :class="{ active : tipoOrden == 'directo' }">
                                        <i class="la la-file-invoice-dollar mr-2"></i> Crear orden
                                    </button>
                                    <button class="btn btn-outline-dark" @click="changeOrderType('recurrente')" :class="{ active : tipoOrden == 'recurrente' }" disabled>
                                        <i class="la la-infinity mr-2"></i> Crear suscripción
                                    </button>
                                </div>-->
                                <div class="buyer-seller">
                                    <div class="d-flex justify-content-between mb-3">

                                        <!-- Buscador de clientes            -->
                                        <order-client-search v-model:show-client-search="showClientSearch"></order-client-search>

                                        <!-- Si el cliente no ha sido seleccionado            -->
                                        <div v-show="!OrderGetClientSelected.taxId" class="buyer-info">
                                            <div class="media">
                                                <div class="media-body text-left">
                                                    <div class="mb-2">
                                                        <h4>Sin cliente seleccionado</h4>
                                                    </div>
                                                    <div class="btn btn-outline-primary cursor-pointer mt-1" @click="showClientSearchBoxWindow">
                                                        <i class="fa fa-search-plus mr-2"></i><a> Buscar cliente</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-show="OrderGetClientSelected.taxId" class="buyer-info">
                                            <div class="media">
                                                <div class="media-body">
                                                    <h4 class="text-primary">Cliente (NIT: {{ OrderGetClientSelected.taxId }})</h4>
                                                    <h5 class="text-secondary">
                                                        {{ OrderGetClientSelected.nombres }} {{ OrderGetClientSelected.apellidos }}<br>
                                                        <!--<small>{{ OrderGetClientSelected.apellidos }}</small>-->
                                                    </h5>
                                                    <div v-show="OrderGetClientSelected.email">{{ OrderGetClientSelected.email }}</div>
                                                    <div v-show="OrderGetClientSelected.telefono">Tel. {{ OrderGetClientSelected.telefono }}</div>
                                                    <div class="mt-2">
                                                        <div class="text-primary cursor-pointer" @click="showClientSearchBoxWindow">
                                                            <i class="mdi mdi-sync"></i> Cambiar cliente
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="seller-info text-right d-none d-sm-block">
                                            <div class="media">
                                                <div class="media-body">
                                                    <h4>Vendedor</h4>
                                                    <h5 class="text-secondary">
                                                        {{ AuthGetUserInfo.nombres }} {{ AuthGetUserInfo.apellidos }}
                                                    </h5>
                                                    <span>{{ AuthGetUserInfo.email }}</span>
                                                    <div class="btn btn-sm btn-outline-info" @click="handleCloseCash">Cierre de caja <i class="fa fa-question-circle" v-tooltip="'Se hará corte de caja, ya sea parcial o diario.'"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div v-if="modalNewIsVisible" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="modal-cita-titulo">Cierre de caja {{dayjs().format('MMMM D, YYYY h:mm A')}}</h5>
                                                <button type="button" class="close" @click="handleHistoryModal(3)" data-dismiss="modal" aria-label="Cerrar">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div  v-if="!closeCashLoader" class="table-responsive">
                                                    <table class="table">
                                                        <thead class="thead-dark">
                                                        <tr>
                                                            <th scope="col">Campo</th>
                                                            <th scope="col">Valor</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row">Ventas</th>
                                                            <td class="pl-3 ">{{ reportes.ventas_sin_corte.total }}</td>
                                                        </tr>
                                                        <tr >
                                                            <th scope="row">Cobros en efectivo</th>
                                                            <td class="pl-3">{{ reportes.ventas_sin_corte.cash }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Cobros en tarjeta</th>
                                                            <td class="pl-3">{{ reportes.ventas_sin_corte.card}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Cobros por transferencia</th>
                                                            <td class="pl-3">{{ reportes.ventas_sin_corte.bank }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Cobros con link</th>
                                                            <td class="pl-3">{{ reportes.ventas_sin_corte.link }}</td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <th scope="row">Cobros con POS NeoNet</th>
                                                            <td>No se encontraron ventas sin cierre</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Cobros con POS Credomátic</th>
                                                            <td>No se encontraron ventas sin cierre</td>
                                                        </tr> -->
                                                        <!-- <tr>
                                                            <th scope="row">Efectivo Teórico</th>
                                                            <td>No se encontraron ventas sin cierre</td>
                                                        </tr> -->
                                                        <tr>
                                                            <th scope="row">Venta estimada</th>
                                                            <td class="pl-3">{{ reportes.estv_sin_corte.total }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Diferencia</th>
                                                            <td class="pl-3">{{ handleSalesDiference() }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Efectivo real</th>
                                                            <td><input v-model="realCash" type="number" class="form-control"></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Gastos</th>
                                                            <td><input v-model="bills" type="number" class="form-control" ></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Observaciones</th>
                                                            <td><textarea  v-model="observations" class="form-control textCamp" placeholder="Ingresa información adicional del cierre de caja" ></textarea></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div v-else class="d-flex justify-content-center align-items-center p-10">
                                                    <div class="spinner-border text-primary" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                    </div>
                                                 </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button v-if="!closeCashLoader" type="button" class="btn btn-primary" @click="handleCierreCaja" data-dismiss="modal">Hacer cierre</button>
                                                <button type="button" class="btn btn-success" @click="handleHistoryModal(1)" data-dismiss="modal">Ver historial de cierre</button>
                                                <button type="button" class="btn btn-danger" @click="handleHistoryModal(3)" data-dismiss="modal">Cerrar</button>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>

                                <!-- MODAL HISTORIAL DE CIERRE -->
                                <div v-if="showHistoryModal" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="modal-cita-titulo">Historial de cierre</h5>
                                                <button type="button" class="close" @click="handleHistoryModal(3)" data-dismiss="modal" aria-label="Cerrar">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead class="thead-dark">
                                                        <tr>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Fecha y hora de corte</th>
                                                            <th scope="col">Monto total</th>
                                                            <th scope="col">Efectivo real</th>
                                                            <th scope="col">Gastos</th>
                                                            <th scope="col">Diferencia</th>
                                                            <th scope="col">Observaciones</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(item, index) in history" :key="index">
                                                            <td class="pl-3 ">{{ item.id }}</td>
                                                            <td class="pl-3 ">{{ item.fechaCorte+item.horaCorte }}</td>
                                                            <td class="pl-3 ">{{ item.montoTotal }}</td>
                                                            <td class="pl-3 ">{{ item.efectivoReal }}</td>
                                                            <td class="pl-3 ">{{ item.gastos }}</td>
                                                            <td class="pl-3 ">{{ item.diferenciaEfectivo }}</td>
                                                            <td class="pl-3 ">{{ item.observaciones }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-success" @click="handleHistoryModal(2)" data-dismiss="modal">Ver resumen de caja</button>
                                                <button type="button" class="btn btn-danger" @click="handleHistoryModal(3)" data-dismiss="modal">Cerrar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="buy-sell-widget pb-2">
                                    <div v-if="tipoOrden === 'recurrente'" class="mb-3 text-center">
                                        Las ordenes de pago recurrente, requieren la selección de productos
                                    </div>
                                </div>
                                <div>
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <div v-if="tipoOrden === 'directo'" class="text-center mb-4 pt-2">
                                                <label class="toggle">
                                                    <input class="toggle-checkbox" type="checkbox" v-model="hasProducts" @click="changeHasProducts"/>
                                                    <div class="toggle-switch"></div>
                                                    <span class="toggle-label">Mi orden contiene productos</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div v-if="hasProducts">
                                                <div class="mb-4">
                                                    <button type="button" class="btn btn-primary btn-block" @click="showProductSearchBoxWindow">
                                                        <i class="fa fa-search"></i> Buscar productos o servicios
                                                    </button>
                                                    <order-product-search v-model:show-product-search-box="showProductSearchBox" :tipo-orden="tipoOrden" ref="searchComponent"></order-product-search>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 montoCustom" v-if="!hasProducts">
                                            <div class="titleContainer">
                                                <h6>Agregar monto personalizado a orden</h6>
                                            </div>
                                            <div class="row my-2">
                                                <div class="col-12 col-sm-4">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Monto a cobrar</label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <label class="input-group-text"><i class="fa fa-money"></i></label>
                                                            </div>
                                                            <input type="text" class="form-control error" placeholder="0.00" name="montoDirecto" id="montoDirecto" v-model="montoDirecto" aria-invalid="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-5">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Descripción</label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <label class="input-group-text"><i class="fa fa-comment"></i></label>
                                                            </div>
                                                            <input type="text" class="form-control error" placeholder="Escribe aquí" name="descripcionDirecto" id="descripcionDirecto" v-model="descripcionDirecto" aria-invalid="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-3 text-right">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2 d-none d-sm-block">&nbsp;</label>
                                                        <button type="button" class="btn btn-outline-success btn-block" @click="addDirectAmount">
                                                            <i class="fa fa-cart-shopping mr-2"></i> Agregar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<hr>
                                    <div class="form-group">
                                        <label class="mr-sm-2">Método de pago</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <label class="input-group-text"><i class="fa fa-handshake-o"></i></label>
                                            </div>
                                            <select id="method" class="form-control" v-model="method" name="method">
                                                <option value="tarjeta">Pago con tarjeta</option>
                                                <option value="bank">Pago en efectivo</option>
                                            </select>
                                        </div>
                                    </div>-->
                                    <!--   <p class="p-4">
                                            Al crear la orden usted acepta los términos y condiciones de anysubscriptions.com, así como sus afiliados. Any Subscriptions no es responsable del manejo, envío y entrega de cualquier producto o servicio incluido en esta orden.
                                        </p>-->
                                </div>
                                <div class="productsRows">
                                    <div class="d-block d-sm-none">
                                        <hr>
                                        <h5 class="text-primary text-center">Productos o servicios</h5>
                                    </div>
                                    <div class="item itemProduct font-weight-bold d-none d-md-block">
                                        <div class="row">
                                            <div class="col-4 text-left">
                                                Producto o servicio
                                            </div>
                                            <div class="col-3 text-center">
                                                Cantidad
                                            </div>
                                            <div class="col-2 text-right">
                                                Precio
                                            </div>
                                            <div class="col-3 text-right">
                                                Total
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item" v-for="item in OrderGetPosCart" :key="item.id">
                                        <div class="row">
                                            <div class="col-12 col-md-4 text-left">
                                                <div class="d-block d-sm-none font-weight-bold">
                                                    Descripción:
                                                </div>
                                                {{ item.product.nombre }}
                                                <span v-if="item.product.id !== '_DIRECT_AMOUNT_'" @click="removeProduct(item.product.id)">
                                                    <i class="fa fa-trash text-danger cursor-pointer ml-2"></i>
                                                </span>
                                            </div>
                                            <div class="col-12 col-md-3 text-right">
                                                <div class="d-block d-sm-none font-weight-bold text-left">
                                                    Cantidad:
                                                </div>
                                                <div class="NumberInput" v-if="item.product.id !== '_DIRECT_AMOUNT_'">
                                                    <button type="button" class="btn btn-outline-primary" @click="decrement(item.product.id, item.quantity, item.product.precio)">&mdash;</button>
                                                    <input class="form-control" type="text" v-model="item.quantity" @change="updatePosQuantity(item.product.id, item.quantity, item.product.precio)">
                                                    <button type="button" class="btn btn-outline-primary" @click="increment(item.product.id, item.quantity, item.product.precio)">&#xff0b;</button>
                                                </div>
                                                <div v-else>
                                                    x {{ item.quantity }}
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-2 text-right">
                                                <div class="d-block d-sm-none font-weight-bold text-left">
                                                    Precio:
                                                </div>
                                                <input class="form-control no-border" @change="updatePosQuantity(item.product.id, item.quantity, item.product.precio)" type="number" v-model="item.product.precio" v-if="item.product.precioConveniencia">
                                                <span v-else>
                                                    <div v-if="item.product.onSale">
                                                        {{ Numbro((item.product.precioOnSale) ? parseFloat(item.product.precioOnSale) : 0).formatCurrency() }}
                                                    </div>
                                                    <div v-else>
                                                        {{ Numbro((item.product.precio) ? parseFloat(item.product.precio) : 0).formatCurrency() }}
                                                    </div>
                                                </span>
                                            </div>
                                            <div class="col-12 col-md-3 text-right">
                                                <div class="d-block d-sm-none font-weight-bold text-left">
                                                    Impuestos:
                                                </div>
                                                {{ Numbro((item.product.precio) ? parseFloat(item.product.subTotal) : 0).formatCurrency() }}
                                                <ul class="list-group p-0" v-if="emitirFactura">
                                                    <li v-if="item.product.impuestoNombre" class="list-group-item small p-0">{{ item.product.impuestoNombre }}
                                                        <span class="badge">{{ Numbro((item.product.impuestoTotal) ? parseFloat(item.product.impuestoTotal) : 0).formatCurrency() }}</span>
                                                    </li>
                                                    <li class="list-group-item small p-0">IVA
                                                        <span class="badge">{{ Numbro((item.product.iva) ? parseFloat(item.product.iva) : 0).formatCurrency() }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item text-primary font-weight-bold">
                                        <div class="row" v-if="emitirFactura">
                                            <div class="col-6 col-sm-10 col-md-10 col-lg-9 text-left">
                                                Impuestos adicionales
                                            </div>
                                            <div class="col-6 col-sm-2 col-md-2 col-lg-3 text-right">
                                                {{ Numbro((impuestos) ? parseFloat(impuestos) : 0).formatCurrency() }}
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-6 col-sm-10 col-md-10 col-lg-9 text-left">
                                                <h4 class="font-weight-bold text-success">Total a cobrar</h4>
                                            </div>
                                            <div class="col-6 col-sm-2 col-md-2 col-lg-3 text-right">
                                                <h4 class="font-weight-bold text-success">{{ Numbro((montoProductos) ? parseFloat(montoProductos) : 0).formatCurrency() }}</h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card">
                            <div class="card-body">
                                <div>
                                    <div>
                                        <label class="toggle">
                                            <input class="toggle-checkbox" type="checkbox" v-model="pagoParcial"/>
                                            <div class="toggle-switch"></div>
                                            <span class="toggle-label">
                                                Habilitar pagos parciales
                                                <i class="fa fa-question-circle" v-tooltip="'Los clientes podrán cancelar esta orden con distingos pagos a modo de abono, la orden permanecerá pendiente hasta completar el monto total'"></i>
                                            </span>
                                        </label>
                                    </div>
                                    <div class="mt-3" v-if="Access('orders-pos-fel')">
                                        <hr>
                                        <label class="toggle pb-4">
                                            <input class="toggle-checkbox" type="checkbox" v-model="emitirFactura"/>
                                            <div class="toggle-switch"></div>
                                            <span class="toggle-label">
                                                    Emitir factura
                                                    <i class="fa fa-question-circle" v-tooltip="'Se emitirá una factura tributaria correspondiente al monto de esta orden'"></i>
                                                </span>
                                        </label>
                                        <div class="row" v-if="emitirFactura">
                                            <div class="form-group col-lg-12 col-sm-12" v-if="Object.keys(locaciones).length > 0">
                                                Seleccione la locación
                                                <multiselect v-model="locacionId"
                                                             :options="locaciones"
                                                             track-by="id" label="nombre"
                                                             :close-on-select="true"
                                                             :clear-on-select="true"
                                                             :preserve-search="true"
                                                             placeholder="Selecciona una locación para facturar"
                                                             :multiple="false"
                                                             :internal-search="true"
                                                >
                                                </multiselect>

                                            </div>

                                            <div class="form-group col-lg-12 col-sm-12 ">
                                                Fecha de factura
                                                <DatePicker v-model="invoiceDate" mode="date" :attributes="facturacionCalendar" :max-date="lastDayOfMonth" :min-date="minDateInvoice">
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                                    </template>
                                                </DatePicker>
                                            </div>
                                            <div class="form-group mb-0 col-lg-12 col-sm-12 ">
                                                <label class="toggle" v-if="emitirFactura">
                                                    <input class="toggle-checkbox" type="checkbox" v-model="isCambiaria"/>
                                                    <div class="toggle-switch"></div>
                                                    <span class="toggle-label">
                                                            Factura cambiaria
                                                            <i class="fa fa-question-circle" v-tooltip="'Se emitirá una factura tributaria correspondiente al monto de esta orden'"></i>
                                                        </span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <label class="toggle pb-4" v-if="!emitirFactura">
                                        <input class="toggle-checkbox" type="checkbox" v-model="emitirRecibo"/>
                                        <div class="toggle-switch"></div>
                                        <span class="toggle-label">
                                                    Emitir recibo
                                                    <i class="fa fa-question-circle" v-tooltip="'Se emitira un recibo al correo electrónico'"></i>
                                                </span>
                                    </label>
                                    <hr>
                                </div>
                                <div class="mb-1 text-muted">
                                    Opciones de pago:
                                </div>
                                <div class="p-2">
                                    <div class="row" v-if="!paymentMethod || paymentMethod === '' || paymentMethod === 'later_on_store'">

                                        <div class="col-12 col-sm-12 px-1 mb-2">
                                            <div type="button" :class="{'btn btn-primary btn-block': (paymentMethod === 'later_on_store'), 'btn btn-outline-primary btn-block': (paymentMethod !== 'later_on_store')}" @click="paymentMethod = 'later_on_store'; createOrder()">
                                                <i class="fa fa-qrcode mr-1"></i> Pagar con QR o enlace de pago
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 px-1 mb-2">
                                            <div :class="{'btn btn-primary btn-block': (paymentMethod === 'cash'), 'btn btn-outline-primary btn-block': (paymentMethod !== 'cash')}" @click="paymentMethod = 'cash'">
                                                <i class="fa fa-money mr-1"></i> Efectivo
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 px-1 mb-2">
                                            <div :class="{'btn btn-primary btn-block': (paymentMethod === 'card'), 'btn btn-outline-primary btn-block': (paymentMethod !== 'card')}" @click="paymentMethod = 'card';">
                                                <i class="fa fa-credit-card mr-1"></i> Tarjeta
                                            </div>
                                            <PaymentForm v-model:show-payment-form="showCardPaymentForm" :redirect-data="payRedirectionData"  @pay="createOrder" @finish="paymentFinish" :total-pago="montoProductos" :afiliacion="afiliacionPago"></PaymentForm>
                                        </div>
                                        <div class="col-12 col-sm-6 px-1 mb-2">
                                            <div :class="{'btn btn-primary btn-block': (paymentMethod === 'pos'), 'btn btn-outline-primary btn-block': (paymentMethod !== 'pos')}" @click="paymentMethod = 'pos'">
                                                <i class="fa fa-edit mr-1"></i> Voucher
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 px-1 mb-2">
                                            <div :class="{'btn btn-primary btn-block': (paymentMethod === 'bank'), 'btn btn-outline-primary btn-block': (paymentMethod !== 'bank')}" @click="paymentMethod = 'bank'">
                                                <i class="fa fa-bank mr-1"></i> Transferencia
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="paymentMethod === 'card'" class="text-left">
                                        <hr>
                                        <h5 class="text-success">Pago con tarjeta</h5>
                                        <div>
                                            <label>Pasarela de pago</label>
                                            <select class="form-control" v-model="afiliacionPago">
                                                <option v-for="item in afiliaciones" :value="item" :key="item.id">{{ item.nombre }}</option>
                                            </select>
                                        </div>
                                        <div v-if="habilitarCuotas">
                                            <div v-if="cuotasMinimo > 0 && montoProductos >= cuotasMinimo">
                                                <div>
                                                    <label>Cuotas</label>
                                                    <select class="form-control" v-model="cuotasPago">
                                                        <option value="0">--</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="6">6</option>
                                                        <option value="10">10</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <div class="mt-3 text-small">
                                                        <div v-if="cuotasPago > 0">
                                                            Cuotas de:
                                                            <span class="font-weight-bold">{{ Numbro((cuotasAmount) ? parseInt(cuotasAmount) : 0).formatCurrency() }}/mes</span>
                                                            <div class="text-small text-muted">
                                                                Aproximadamente*
                                                            </div>
                                                        </div>
                                                        <div v-else class="text-muted">
                                                            No se realizará cobro con cuotas
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-muted text-center text-small">
                                                Esta compra no es aplicable para cuotas
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-dark w-100 mt-3" @click="showCardPaymentForm = 'card'">
                                            <i class="fa fa-check-circle mr-1"></i> Realizar pago
                                        </button>
                                    </div>
                                    <div v-if="paymentMethod === 'qr'" class="cardFormPay">
                                        <hr>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="text-center mb-3">
                                                    <img src="staticAssets/anysubscriptions.com/paygwallet.png" style="max-width: 100px">
                                                </div>
                                                <h6>
                                                    Pagar mediante código PayG Wallet
                                                    <i class="fa fa-question-circle ml-1" v-tooltip="'Permite pagar utilizando un código QR generado desde la aplicación PayG Wallet'"></i>
                                                </h6>
                                                <div class="text-center my-4">
                                                    <button @click="readerStart" class="btn btn-primary">
                                                        <i class="fa-solid fa-qrcode mr-2"></i> Iniciar escáner QR
                                                    </button>
                                                </div>
                                                <div class="text-muted mt-3 text-justify">
                                                    Recuerda, para utilizar el scanner de pagos debes brindar accesos al navegador para utilizar la cámara de tu preferencia.
                                                </div>
                                                <!--<div v-if="qrToken !==''">
                                                    <div class="text-right">
                                                        <button type="button" class="btn btn-dark mb-3 mt-3 px-5" @click="createOrder('card')">
                                                            <i class="fa fa-credit-card mr-1"></i> Realizar pago
                                                        </button>
                                                    </div>
                                                </div>-->
                                                <div>
                                                    <QrReader v-if="validatorStarted" @qrScanned="qrScanned" @readerStop="readerStop" ref="qrReader">
                                                        <div v-if="formDetails.token" class="text-left mb-3">
                                                            <h3>{{ formDetails.nombres }} {{ formDetails.apellidos }}</h3>
                                                            <div>
                                                                <b class="mr-2">Nit:</b> {{ formDetails.taxId }}
                                                            </div>
                                                            <div>
                                                                <b class="mr-2">Total a cobrar:</b> {{ Numbro((montoProductos) ? parseFloat(montoProductos) : 0).formatCurrency() }}
                                                            </div>
                                                            <hr>
                                                            <div>
                                                                <button type="button" class="btn btn-primary mb-3 mt-3 px-5 btn-block" @click="createOrder(); $refs.qrReader.closeScanner();">
                                                                    <i class="fa fa-credit-card mr-1"></i> Realizar pago
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <hr>
                                                            <div class="mt-5 text-danger">
                                                                <i class="fa-solid fa-times-circle fa-4x"></i>
                                                            </div>
                                                            <h6 class="text-danger mt-3">Error, código inválido</h6>
                                                            <div class="btn btn-primary mt-3" @click="$refs.qrReader.resetScanner()">
                                                                Volver a escanear
                                                            </div>
                                                        </div>
                                                    </QrReader>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div v-if="paymentMethod === 'cash'" class="text-left">
                                        <hr>
                                        <h5 class="text-success">Pago con efectivo</h5>
                                        <div>
                                            <label class="text-primary">
                                                Efectivo recibido:
                                            </label>
                                            <input class="form-control" type="text" @keyup="calculateCash">
                                        </div>
                                        <div class="mt-3">
                                            <span v-if="cashVueltoAmount >= 0" class="text-warning">
                                                Entregar {{ Numbro((cashVueltoAmount) ? parseFloat(cashVueltoAmount) : 0).formatCurrency() }} de cambio
                                            </span>
                                            <span v-if="cashVueltoAmount < 0" class="text-danger">
                                                El monto no cubre el total de la orden
                                            </span>
                                        </div>
                                        <button type="button" class="btn btn-dark w-100 mt-3" @click="createOrder">
                                            <i class="fa fa-check-circle mr-1"></i> Realizar pago
                                        </button>
                                    </div>
                                    <div v-if="paymentMethod === 'bank'" class="text-left">
                                        <hr>
                                        <h5 class="text-success">Pago con transferencia bancaria</h5>
                                        <div>
                                            <label class="text-primary">
                                                Monto recibido:
                                            </label>
                                            <input class="form-control" type="text" @keyup="calculateCash">
                                        </div>
                                        <div class="mt-3">
                                            <span v-if="cashVueltoAmount < 0" class="text-danger">
                                                El monto no cubre el total de la orden
                                            </span>
                                        </div>
                                        <button type="button" class="btn btn-dark w-100 mt-3" @click="createOrder">
                                            <i class="fa fa-check-circle mr-1"></i> Realizar pago
                                        </button>
                                    </div>
                                    <div v-if="paymentMethod === 'pos'" class="text-left">
                                        <hr>
                                        <h5 class="text-success">Pago con voucher</h5>
                                        <div>
                                            <label>
                                                Ingrese el número de autorización del voucher
                                            </label>
                                            <input class="form-control" type="text" v-model="authVoucher">
                                            <label class="toggle mt-2 mr-1" v-if="paymentMethod === 'pos'">
                                                <input class="toggle-checkbox" type="checkbox" v-model="tipoVoucherV"/>
                                                <div class="toggle-switch"></div>
                                                <span class="toggle-label">
                                                    VisaNet
                                                </span>

                                            </label>
                                            <label class="toggle mt-2" v-if="paymentMethod === 'pos'">
                                                <input class="toggle-checkbox" type="checkbox" v-model="tipoVoucher"/>
                                                <div class="toggle-switch"></div>
                                                <span class="toggle-label">
                                                    Credomátic
                                                </span>
                                            </label>
                                        </div>
                                        <button type="button" class="btn btn-dark w-100 mt-3" @click="createOrder">
                                            <i class="fa fa-check-circle mr-1"></i> Realizar pago
                                        </button>
                                    </div>
                                    <div class="text-center mt-3" v-if="paymentMethod !== '' && paymentMethod !== 'later_on_store'">
                                        <a @click="paymentMethod = ''" class="text-danger text-decoration-underline">Cancelar pago</a>
                                    </div>
                                </div>

                                <div class="mt-3">
                                    <hr>
                                </div>
                                <div class="col-12 col-sm-12 px-1 mb-2">
                                    <div type="button" :class="{'btn btn-primary btn-block': (paymentMethod === 'later_on_store'), 'btn btn-outline-primary btn-block': (paymentMethod !== 'later_on_store')}" @click="paymentMethod = 'later_on_store'; createOrder()">
                                        <i class="fa fa-save mr-1"></i> Guardar Orden
                                    </div>
                                </div>
                                <div class="mt-3 text-center">
                                    <a class="text-danger" @click="clearPos(false, true)">Limpiar e iniciar nueva orden</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="showOrderDetailsCreation">
                <!--<div class="row" v-if="1">-->
                    <div class="col-12 text-center">
                        <div class="card payLink">
                            <div v-if="paymentOk" class="card-body">
                                <h4>Orden creada con éxito</h4>
                                <div class="mt-4">
                                    La orden fue creada con éxito, si el cliente posee correo electrónico, se envió un comprobante a dicha dirección.
                                </div>
                                <div v-if="facturaDetails.fcUID" class="mt-4 text-left">
                                    <hr>
                                    <h4>Factura generada con éxito</h4>
                                    <div>
                                        <b>Autorización:</b> {{ facturaDetails.fcUID }} <br>
                                        <b>Serie:</b> {{ facturaDetails.serie }} <br>
                                        <b>Número:</b> {{ facturaDetails.numero }}
                                    </div>
                                    <div class="mt-3">
                                        Puede visualizar la factura en el siguiente enlace:
                                    </div>
                                    <div class="my-4">
                                        <a class="text-primary" :href="facturaDetails.link" target="_blank">{{ facturaDetails.link }}</a>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 my-3">
                                            <i class="fa fa-handshake-o mr-3"></i> Reenviar por correo electrónico
                                        </div>
                                        <div class="col-xs-12 col-lg-8 col-md-8">
                                            <input class="form-control" type="email" placeholder="Escribe aquí" v-model="customEmailSendFactura"/>
                                        </div>
                                        <div class="col-xs-12 col-lg-4 col-md-4">
                                            <button class="btn btn-success btn-block" type="button" @click="resendInvoice">Reenviar</button>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <i class="fa fa-print mr-3"></i> Imprimir factura
                                        </div>
                                        <div class="col-12 mt-3 text-center">
                                            <button class="btn btn-success" type="button" @click="printInvoice">Realizar impresión</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="reciboDetails.ordenNo" class="mt-4 text-left">
                                    <hr>
                                    <h4>Recibo enviado con éxito</h4>
                                    <div>
                                        <b>Número:</b> {{ facturaDetails.ordenNo }}
                                    </div>
                                    <div class="mt-3">
                                        Puede visualizar el recibo en el siguiente enlace:
                                    </div>
                                    <div class="my-4">
                                        <a class="text-primary" :href="reciboDetails.link" target="_blank">{{ reciboDetails.link }}</a>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 my-3">
                                            <i class="fa fa-handshake-o mr-3"></i> Reenviar por correo electrónico
                                        </div>
                                        <div class="col-xs-12 col-lg-8 col-md-8">
                                            <input class="form-control" type="email" placeholder="Escribe aquí" v-model="customEmailSendRecibo"/>
                                        </div>
                                        <div class="col-xs-12 col-lg-4 col-md-4">
                                            <button class="btn btn-success btn-block" type="button" @click="resendRecibo">Reenviar</button>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4">
                                <div>
                                    <button class="btn btn-primary mr-2" @click="backToOrderDetail()">Ver detalle de orden</button>
                                    <button class="btn btn-primary" @click="clearPos(true, true)">Crear nueva orden</button>
                                </div>
                            </div>
                            <div v-else class="card-body">
                                <h4>Orden No.{{orderCreated}} - Enlace de pago generado</h4>
                                <div class="mt-4">
                                    Si es un cliente registrado, se envió un correo electrónico con el link de pago automáticamente.
                                </div>
                                <hr>
                                <div>
                                    <h4 class="my-2"><i class="fas fa-link mr-2"></i>Link de pago</h4>
                                    <a class="text-primary text-decoration-underline" :href="orderPayLink" target="_blank">{{ orderPayLink }}</a>
                                </div>
                                <hr>
                                <div class="text-center my-4">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 mb-4">
                                            <h4 class="mt-2"><i class="fas fa-mobile mr-2"></i>Pago con QR</h4>
                                            <div class="mt-3">
                                                <img :src="orderPayLinkQr" style="max-width: 100%"/>
                                            </div>
                                            <div class="text-muted text-small">
                                                Escanea el código QR con tu teléfono para pagar de forma rápida y fácil
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 mb-4">
                                            <h4 class="mt-2"><i class="fa fa-envelope mr-2"></i>Reenvío de link de pago</h4>
                                            <div class="mt-4">
                                                <div>
                                                    Reenviar por correo electrónico
                                                </div>
                                                <div class="my-2">
                                                    <input class="form-control" type="email" placeholder="Escribe aquí" v-model="orderResendTo"/>
                                                </div>
                                                <div class="mt-3">
                                                    <button class="btn btn-success btn-sm" type="button" @click="resendOrderPaylink">Reenviar enlace</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="mb-4">
                                <div>
                                    <button class="btn btn-primary" @click="clearPos(true, true)">Crear nueva orden</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<style scoped>

.textCamp{
    max-height: 100%;
    resize: none;
}

</style>

<script>
import {mapGetters, mapActions} from "vuex";
import OrderProductSearch from "../components/OrderProductSearch";
import OrderClientSearch from "../components/OrderClientSearch";
import Layout from "/src/layout/LayoutLoader.vue";
//import NumberInput from "src/components/NumberInput";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import AndroidBridge from "src/core/AndroidBridge";
import {store} from "src/store";
import Numbro from "numbro";
import PaymentForm from "src/modules/order/components/PaymentForm";

import {DatePicker} from 'v-calendar';
import Multiselect from 'vue-multiselect';

import QrReader from 'src/components/QrReader';
import ReservasAgendarInLine from "../components/ReservasAgendarInLine.vue";
import ClientesInLine from "../components/clientesInLine.vue";
import EventosInLine from "../components/eventosInLine.vue";
import dayjs from "dayjs";
//import tools from "src/core/Tools";

export default {
    name: "Index",
    components: {
        EventosInLine, ClientesInLine, ReservasAgendarInLine,
        Layout,
        OrderProductSearch,
        OrderClientSearch,
        //NumberInput,
        Multiselect,
        QrReader,
        DatePicker,
        PaymentForm,
    },
    data() {
        return {
            // spiner
            closeCashLoader:false,

            locaciones:{},
            locacionId:{id:0},
            BridgeAndroid: false,
            Tools: Tools,
            posToEdit: false,
            showOrderDetailsCreation: false,
            paymentOk: false,
            paymentMethod: '',
            showClientSearch: false,
            showProductSearchBox: false,
            orderCreatedId: 0,
            orderCreated: {},
            orderPayLink: '',
            orderPayLinkQr: '',
            orderResendTo: '',
            tipoOrden: 'directo',
            montoDirecto: null,
            descripcionDirecto: '',
            montoProductos: 0,
            method: "tarjeta",
            hasProducts: true,
            vendedorSelected: {},
            pagoParcial: false,
            emitirFactura: false,
            emitirRecibo: false,

            //Clierre
            modalNewIsVisible: false,
            showHistoryModal:false,

            // payment
            minCardYear: new Date().getFullYear(),
            cardNumber: '',
            cardName: '',
            cardMonth: '',
            cardYear: '',
            cardCvv: '',
            authVoucher: '',
            tipoVoucher: '',
            referenceVoucher: '',
            cashVueltoAmount: 0,
            cashReceived: 0,

            //Scanner
            validatorStarted: false,
            formDetails: {},
            qrScannedResult: '',
            qrToken: '',
            habilitarCuotas: false,
            cuotasMinimo: 0,
            cuotasPago: 0,
            cuotasAmount: 0,
            afiliaciones: {},
            afiliacionPago: false,

            // pago
            isLoading: false,
            showCardPaymentForm: false,
            payRedirectionData: "none",

            // facturacion
            facturaDetails: {},
            reciboDetails: {},
            customEmailSendFactura: '',
            customEmailSendRecibo: '',
            invoiceDate: new Date(),
            lastDayOfMonth: '',
            minDateInvoice: '',
            isCambiaria: false,
            impuestos: 0,
            iva: 0,
            detalleImpuesto: [],
            facturacionCalendar: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,
                    dates: new Date(),

                }
            ],
             reportes: {
                "ventas_finalizadas": {
                    "bank": 0,
                    "card":0,
                    "cash":0,
                    "link":0,
                    "total":0
                },
                "ventas_sin_corte": {
                    "bank": 0,
                    "card":0,
                    "cash":0,
                    "link":0,
                    "total":0
                },
                "ventas_en_progreso": {
                    "pagadas": 0,   
                    "sin_pago":0
                },
                "ventas": {
                    "mv": 0,
                },
                "ordenes": {
                    "pos": 0,
                    "tienda": 0,
                    "mv": 0,
                    "total": 0
                },
                "estv": {
                    "pos": 0,
                    "tienda": 0,
                    "total": 0
                },
                "estv_sin_corte": {
                    "total": 0
                },
            },
                realCash:0,
                bills:0,
                observations:'',

                cortadas:[],
                history:[]
        };
    },
    computed: {
        ...mapGetters({
            Access: 'Access',
            AuthGetUserInfo: 'AuthGetUserInfo',
            OrderGetPosCart: 'OrderGetPosCart',
            OrderGetClientSelected: 'OrderGetClientSelected',
            OrderGetPosCartCurrentOrder: 'OrderGetPosCartCurrentOrder',
        }),
        minCardMonth() {
            if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1
            return 1
        },
    },
    watch: {
        showClientSearch: function (val) {
            if (val) {
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
        showProductSearchBox: function (val) {
            if (val) {
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
        OrderGetPosCart: function () {
            this.calculateProductsAmount();
        },
        /*emitirFactura: function () {
            this.calculateProductsAmount(); // creo que esta función choca con la variable emitirFactura
        },*/
        cuotasPago(val) {
            if (val > 0) {
                this.cuotasAmount = (parseFloat(this.montoProductos) / parseInt(val));
            }
            this.cuotasPago = val;
        },
    },
    mounted() {
        const self = this;
        this.BridgeAndroid = new AndroidBridge();
        // this.emitirFactura = this.Access('orders-pos-fel');
        if(this.Access('orders-pos-fel')){
            this.getLocaciones();
        }
        self.getCloseHistory();
        // por si es edición
        if (typeof this.$route.params.id !== 'undefined' && parseInt(this.$route.params.id) > 0) {
            this.posToEdit = true;
            this.orderCreatedId = parseInt(this.$route.params.id);
        }

        //limpio el pos al entrar
        if (!this.posToEdit) {
            this.clearPos(true, true);
        }

        this.calculateProductsAmount();
        //this.orderCreatedId = store.getters.OrderGetPosCartCurrentOrder;

        this.lastDayOfMonth = new Date(self.invoiceDate.getFullYear(), self.invoiceDate.getMonth() + 1, 0);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 5);

        this.minDateInvoice = minDate;

        // eventos para pago
        const script = document.createElement('script');
        script.type = 'text/javascript';
        if (script.readyState) {  // only required for IE <9
            script.onreadystatechange = function () {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                    script.onreadystatechange = null;
                    self.enableFrEvents();
                }
            };
        }
        else {  //Others
            script.onload = function () {
                setTimeout(function () {
                    self.enableFrEvents();
                }, 1000);
            };
        }
        script.src = 'https://anysubscriptions.com/staticAssets/ifrEvents/ifr-events.js';
        document.getElementsByTagName('head')[0].appendChild(script);

        // habilito cuotas
        if (typeof this.AuthGetUserInfo.configuration !== 'undefined' && typeof this.AuthGetUserInfo.configuration.payments !== 'undefined' && typeof this.AuthGetUserInfo.configuration.payments.enableCuotas !== 'undefined') {
            this.habilitarCuotas = !!(this.AuthGetUserInfo.configuration.payments.enableCuotas);
            this.cuotasMinimo = parseFloat(this.AuthGetUserInfo.configuration.payments.cuotasMinTC);
        }

        this.getAffiliations();
    },
    methods: {
        dayjs,
        Numbro,
        ...mapActions({
            OrderAddProductToPos: 'OrderAddProductToPos',
            OrderChangeProductQuantity: 'OrderChangeProductQuantity',
            OrderClearProducts: 'OrderClearProducts',
            OrderRemoveProduct: 'OrderRemoveProduct',
            OrderSaveCurrentOrder: 'OrderSaveCurrentOrder',
        }),
        handleHistoryModal(modal){
            switch (modal) {
                case 1:
                    this.showHistoryModal = true;
                    this.modalNewIsVisible = false;
                    break;
                case 2:
                    this.showHistoryModal = false;
                    this.modalNewIsVisible = true;
                    break;
                case 3:
                    this.modalNewIsVisible = false;
                    this.showHistoryModal = false;
                    break;
                default:
                    this.modalNewIsVisible = false;
                    this.showHistoryModal = false;
                    break;
            }
        },
        handleCierreCaja(){
             const self = this;

            let corteData= {
                'usuarioId' : self.AuthGetUserInfo.id,
                'montoTotal' : self.reportes.ventas_sin_corte.total,
                'pagosEfectivo' :  self.reportes.ventas_sin_corte.cash,
                'pagosTarjeta' : self.reportes.ventas_sin_corte.card,
                'pagosLink' : self.reportes.ventas_sin_corte.link,
                'pagosTransferencia' :  self.reportes.ventas_sin_corte.bank,
                'gastos' : self.bills,
                'efectivoReal' : self.realCash,
                'observaciones' : self.observations
            }


            console.log(corteData);
            API.send('POST', 'reports/corteDeCaja', corteData,
                    function (response) {
                        self.cortadas= response.data.cortadas;
                        self.history= response.data.history;
                        self.realCash=0;
                        self.bills=0;
                        self.observations='';
                        self.handleHistoryModal(1);
                        self.getSalesData();
                        API.showSuccessAlert(response.msg);
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });

        },
        handleSalesDiference(){
            return  this.realCash > 0 ? this.realCash - this.reportes.ventas_sin_corte.cash : 0;
        },
        handleCloseCash(){
            this.modalNewIsVisible= !this.modalNewIsVisible;
            if(this.modalNewIsVisible){
                this.getSalesData();
            }
        },
        getCloseHistory(){
            const self = this;

            API.send('POST', 'reports/historialDeCorte', {
                'usuarioId' : self.AuthGetUserInfo.id,
            },
                    function (response) {
                        self.history = response.data;
                      
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });

        },
        getSalesData(){
            const self = this;
            this.closeCashLoader= true;
            API.send('GET', 'reports/general/' + "today", {},
                    function (response) {
                        self.reportes = Tools.parseObjectToFloat(response.data);
                        self.closeCashLoader= false;
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                        self.closeCashLoader= false;
                    });

        },
        getLocaciones(){
            const self = this;
            API.send('GET', 'locaciones/all/', {},
                function (response) {
                    if (response.status) {
                        self.locaciones = response.data;
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                });
        },

        generateMonthValue(n) {
            return n < 10 ? `0${n}` : n
        },
        calculateCash(event) {
            const cash = parseFloat(event.target.value.replace(/^0+/, ''));
            const total = parseFloat(this.montoProductos);
            this.cashReceived = cash;
            this.cashVueltoAmount = cash - total;
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        calculateProductsAmount() {
            this.montoProductos = 0;
            this.impuestos = 0;
            //console.log(this.OrderGetPosCart);
            for (let item in this.OrderGetPosCart) {
                this.OrderGetPosCart[item]['product']['subTotal'] = 0;
                this.OrderGetPosCart[item]['product']['impuestoTotal'] = 0;
                this.OrderGetPosCart[item]['product']['iva'] = 0;

                if (typeof this.OrderGetPosCart[item] !== 'undefined') {

                    // si es un producto por pago directo
                    if (typeof this.OrderGetPosCart[item]['product']['id'] !== 'undefined' &&
                        typeof this.OrderGetPosCart[item]['product']['id'].startsWith === 'function' &&
                        this.OrderGetPosCart[item]['product']['id'].startsWith('_DIRECT_AMOUNT_')) {
                        this.hasProducts = false;
                    }
                    const productPrice = (this.OrderGetPosCart[item]['product']['onSale']) ? parseFloat(this.OrderGetPosCart[item]['product']['precioOnSale']) : parseFloat(this.OrderGetPosCart[item]['product']['precio']);


                    if ((productPrice > 0) && (typeof this.OrderGetPosCart[item]['quantity'] !== 'undefined')) {
                        const amount = parseFloat(this.OrderGetPosCart[item]['quantity']) * parseFloat(productPrice);
                        this.OrderGetPosCart[item]['product']['subTotal'] = amount;
                        //console.log(productPrice);
                        //const iva = parseInt(this.OrderGetPosCart[item]['quantity']) * parseFloat(this.OrderGetPosCart[item]['product']['impuestos']);
                        if (this.emitirFactura) {
                            if (this.hasProducts) {
                                //console.log(this.OrderGetPosCart[item]['product']);
                                if (typeof this.OrderGetPosCart[item]['product']['impuestoMonto'] !== 'undefined' && this.OrderGetPosCart[item]['product']['impuestoMonto'] !== '0' && this.OrderGetPosCart[item]['product']['impuestoMonto'] !== '') {
                                    const impuestos = parseInt(this.OrderGetPosCart[item]['quantity']) * parseFloat(this.OrderGetPosCart[item]['product']['impuestoMonto']);
                                    this.montoProductos += impuestos + amount;

                                    this.OrderGetPosCart[item]['product']['subTotal'] = impuestos + amount;
                                    this.OrderGetPosCart[item]['product']['impuestoTotal'] = impuestos;
                                    this.impuestos = parseFloat(this.impuestos) + impuestos;
                                }
                                // Si no tiene impuestos, entonces solo sumo el monto
                                else {
                                    this.montoProductos += amount;
                                }
                            }
                            else {
                                const impuestos = 0;
                                this.montoProductos += impuestos + amount;
                            }

                            const montoGravable = amount / 1.12;
                            const IVA = amount - montoGravable;
                            this.OrderGetPosCart[item]['product']['iva'] = IVA;
                        }
                        else {
                            this.montoProductos += amount;
                        }
                    }
                }
            }
            console.log('Precio: *****')
            console.log(this.montoProductos)
            this.montoProductos = this.montoProductos.toFixed(2);
            this.cashVueltoAmount = 0;
        },
        updatePosQuantity(idProduct, quantity, precio) {
            this.$store.dispatch('OrderChangeProductQuantity', {
                id: idProduct,
                quantity: quantity,
                precio: precio,
            })
        },
        removeProduct(idProduct) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function () {

                self.$store.dispatch('OrderRemoveProduct', {
                    id: idProduct,
                })
            })
        },
        showProductSearchBoxWindow() {
            this.showProductSearchBox = true;
            this.$refs.searchComponent.search();
        },
        showClientSearchBoxWindow() {
            this.showClientSearch = true;
        },
        changeOrderType(tipo) {
            const self = this;
            if (tipo === 'recurrente') {
                this.hasProducts = true;
            }
            this.tipoOrden = tipo;
            self.$store.dispatch('OrderClearProducts');
        },
        changeHasProducts() {
            const self = this;
            if (this.tipoOrden === 'recurrente') {
                return false;
            }
            else {
                if (!Tools.objectIsEmpty(this.OrderGetPosCart)) {
                    API.showConfirm('Se removerán los productos seleccionados de la orden', 'Esta acción no se puede deshacer', function () {
                        self.$store.dispatch('OrderClearProducts');
                    }, function () {
                        self.hasProducts = !self.hasProducts;
                    });
                }
            }
        },
        addDirectAmount() {

            const tmpMontoDirecto = parseFloat(this.montoDirecto);

            if (!tmpMontoDirecto || (typeof tmpMontoDirecto !== 'number')) {
                API.showErrorNotify('Debe ingresar un monto válido');
                return false;
            }

            if (this.descripcionDirecto === '') {
                API.showErrorNotify('Debe ingresar una descripción');
                return false;
            }

            const montoDirectoProduct = {
                id: '_DIRECT_AMOUNT_',
                nombre: this.descripcionDirecto,
                precio: parseFloat(this.montoDirecto),
                clearTotal: true,
            };
            this.$store.dispatch('OrderAddProductToPos', montoDirectoProduct);
            this.montoDirecto = null;
            this.descripcionDirecto = '';
        },
        backToOrderDetail() {
            this.$router.push('/order/view/' + this.orderCreatedId);
        },
        clearPos(force, full) {

            const self = this;
            if (!force) force = false;

            const clearPosForced = () => {

                store.dispatch('coreUnlockLoading');

                if (full) {
                    self.paymentOk = false;
                    self.showOrderDetailsCreation = false;
                    self.paymentMethod = false;
                    self.tipoOrden = 'directo';
                    self.montoDirecto = null;
                    self.montoProductos = 0;
                    self.method = "tarjeta";
                    self.hasProducts = true;
                    self.vendedorSelected = {};
                    /*self.orderPayLink = '';
                    self.orderPayLinkQr = '';*/
                    self.orderCreatedId = 0;
                    self.authVoucher = '';
                }
                self.$store.dispatch('OrderClearProducts');
                self.$store.dispatch('OrderPosClientClear');
                self.$store.dispatch('OrderSaveCurrentOrder', 0);
            }

            if (!force) {
                API.showConfirm('¿Está seguro de limpiar la orden actual?', 'Esta acción no se puede deshacer', function () {
                    clearPosForced();
                })
            }
            else {
                clearPosForced();
            }
        },
        resendOrderPaylink() {

            if (this.orderResendTo !== '') {
                API.send('POST', 'orders/resend', {
                        "id": this.orderCreatedId,
                        "email": this.orderResendTo,
                        "type": "email"
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
            else {
                API.showErrorNotify('Ingrese un correo electrónico válido');
            }
        },
        // realizar orden
        createOrder(data) {

            const self = this;
            if (!data) data = {};

            API.showConfirm('Se generará una orden', '¿Desea continuar?', function () {

                const ordenDetalle = {};
                let counter = 0;
                for (let item in self.OrderGetPosCart) {
                    if (typeof self.OrderGetPosCart[item] !== 'undefined') {
                        // Verificar si el id del producto comienza con '_DIRECT_AMOUNT_'
                        if (typeof self.OrderGetPosCart[item]['product']['id'] !== 'undefined' &&
                            typeof self.OrderGetPosCart[item]['product']['id'].startsWith === 'function' &&
                            self.OrderGetPosCart[item]['product']['id'].startsWith('_DIRECT_AMOUNT_')) {
                            ordenDetalle[counter] = {
                                "productoId": 0,
                                "productoPrecio": self.OrderGetPosCart[item]['product']['precio'],
                                "productoCantidad": parseInt(self.OrderGetPosCart[item]['quantity']),
                                "productoDescMontoDirecto": self.OrderGetPosCart[item]['product']['nombre']
                            };
                        } else {
                            // Si no comienza con '_DIRECT_AMOUNT_', procesar como de costumbre
                            const precio = (self.OrderGetPosCart[item]['product']['onSale']) ? self.OrderGetPosCart[item]['product']['precioOnSale'] : self.OrderGetPosCart[item]['product']['precio'];

                            ordenDetalle[counter] = {
                                "productoId": self.OrderGetPosCart[item]['product']['id'],
                                "productoPrecio": precio,
                                "productoCantidad": parseInt(self.OrderGetPosCart[item]['quantity']),
                            };
                        }
                        counter++;
                    }
                }

                if (Object.keys(ordenDetalle).length === 0) {
                    API.showErrorAlert('Su orden no contiene productos');
                    return false;
                }

                if (typeof self.OrderGetClientSelected.nombres === 'undefined') {
                    API.showErrorAlert('Debe seleccionar un cliente o Consumidor Final');
                    return false;
                }

                if (self.paymentMethod === 'later_on_store') {
                    self.emitirFactura = false;
                    self.emitirRecibo = false;
                    self.isCambiaria = false;
                    self.invoiceDate = new Date();
                }

                const dataPay = {
                    "pagoMetodo": self.paymentMethod, // card, pos, cash, qr, contra entrega, recoger en tienda
                    "pagoTcNombre": (typeof data.cardName !== 'undefined') ? data.cardName : '',
                    "pagoTcNumero": (typeof data.cardNumber !== 'undefined') ? data.cardNumber : '',
                    "pagoTcExp": (typeof data.cardMonth !== 'undefined') ? (data.cardMonth + "/" + ((typeof data.cardYear !== 'undefined') ? data.cardYear.toString().substr(2) : '')) : '',
                    "pagoTcCvv": (typeof data.cardCvv !== 'undefined') ? data.cardCvv : '',
                    "pagoCuotas": (typeof data.cardCuotas !== 'undefined') ? data.cardCuotas : '',
                    "pagoAfiliacion": (typeof self.afiliacionPago.method !== 'undefined') ? self.afiliacionPago.method : '',

                    "clienteNombre": (typeof self.OrderGetClientSelected.nombres !== 'undefined') ? self.OrderGetClientSelected.nombres + ' ' + self.OrderGetClientSelected.apellidos : '',
                    "clienteTaxId": (typeof self.OrderGetClientSelected.taxId !== 'undefined') ? self.OrderGetClientSelected.taxId : '',
                    "clienteEmail": (typeof self.OrderGetClientSelected.email !== 'undefined') ? self.OrderGetClientSelected.email : '',
                    "clienteTelefono": (typeof self.OrderGetClientSelected.telefono !== 'undefined') ? self.OrderGetClientSelected.telefono : '',
                    "clienteDireccion": (typeof self.OrderGetClientSelected.direccion !== 'undefined') ? self.OrderGetClientSelected.direccion : '',
                    /*"clientePais": self.paisLocationCobertura,
                    "clienteDepto": self.deptoLocationCobertura,
                    "clienteMuni": self.municipioLocationCobertura,*/

                    /*"envioDireccion": self.direccionEnvioText,
                    "envioPais": self.paisEnvio,
                    "envioDepartamento": self.deptoEnvio,
                    "envioMunicipio": self.municipioEnvio,
                    "envioComentario": self.comentarioEnvio,
                    "envioSeleccionado": {},*/

                    "ordenTiendaDomain": self.AuthGetUserInfo.s,
                    "ordenMonto": parseFloat(self.montoProductos),
                    "ordenDetalle": ordenDetalle,
                    "ordenDesactivarNotificaciones": 1,
                    "ordenId": self.orderCreatedId,

                    "facturaEmitir": self.emitirFactura,
                    "reciboEmitir": self.emitirRecibo,
                    "facturaCambiaria": self.isCambiaria,
                    "facturaFecha": self.invoiceDate,
                    "sucursalId": self.locacionId.id,
                }

                //console.log(dataPay);

                // arreglo shipping providers
                /*let counterShipping = 0;
                for (let sellBy in self.selectedShippingRates) {
                    dataPay['envioSeleccionado'][counterShipping] = {};
                    dataPay['envioSeleccionado'][counterShipping]['localidadId'] = sellBy;
                    dataPay['envioSeleccionado'][counterShipping]['provider'] = self.selectedShippingRates[sellBy]['provider'];
                    dataPay['envioSeleccionado'][counterShipping]['price'] = self.selectedShippingRates[sellBy]['precio'];
                    counterShipping++;
                }*/

                API.send('POST', 'payments/create-pay-order', dataPay,
                    function (response) {

                        if (typeof response.data !== 'undefined') {

                            // si es pago con tc
                            if (response.data.metodoPago === 'card') {

                                // Si trae información de pago con tc
                                if (typeof response.data.extra !== 'undefined') {

                                    // si es visanet o metodos sin redirección
                                    if (response.data.extra.redirectData === "none") {

                                        self.paymentOk = true;
                                        self.orderCreated = response.data.ordenCodigo;
                                        self.orderCreatedId = response.data.ordenId;
                                        self.orderPayLink = response.data.paylink;
                                        self.orderPayLinkQr = response.data.paylink_qr;
                                        if (typeof response.data.extra.factura !== 'undefined') self.facturaDetails = response.data.extra.factura;
                                        if (typeof response.data.extra.recibo !== 'undefined') self.reciboDetails = response.data.extra.recibo;
                                        self.$store.dispatch('OrderSaveCurrentOrder', self.orderCreatedId);
                                        // self.showOrderDetailsCreation = true;
                                        self.showCardPaymentForm = false;
                                        self.clearPos(true, false);
                                        self.$router.push('/orders');
                                    }
                                    // si es powertranz o cardinal
                                    else {
                                        self.payRedirectionData = response.data.extra.redirectData;
                                    }
                                }
                                else {
                                    API.showErrorAlert('Error al crear orden, el procesador de pagos no está disponible');
                                }
                            }
                            else {

                                if (self.paymentMethod === 'later_on_store') {
                                    self.paymentOk = false;
                                }
                                else {
                                    self.paymentOk = true;
                                }

                                self.orderCreated = response.data.ordenCodigo;
                                self.orderCreatedId = response.data.ordenId;
                                self.orderPayLink = response.data.paylink;
                                self.orderPayLinkQr = response.data.paylink_qr;
                                if (typeof response.data.extra.factura !== 'undefined') {
                                    self.facturaDetails = response.data.extra.factura;
                                }
                                if (typeof response.data.extra.recibo !== 'undefined') {
                                    self.reciboDetails = response.data.extra.recibo;
                                }
                                self.$store.dispatch('OrderSaveCurrentOrder', self.orderCreatedId);
                                // self.showOrderDetailsCreation = true;
                                self.clearPos(true, false);
                                self.$router.push('/orders')
                            }
                        }
                        else {
                            API.showErrorAlert('Error al crear orden, por favor, intente de nuevo');
                        }
                    },
                    function (response) {
                        if (typeof response.data.ordenId !== 'undefined') {
                            self.ordenCreadaId = response.data.ordenId;
                        }

                        if (typeof response.data.extra !== 'undefined' && typeof response.data.extra.error !== 'undefined') {
                            API.showErrorAlert(response.data.extra.error);
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    })
            })
        },
        paymentFinish(response) {
            const self = this;

            if (response.status) {
                // Si trae información de pago con tc
                if (typeof response.data.extra !== 'undefined') {

                    // si es visanet o metodos sin redirección
                    if (typeof response.data.extra.auth !== 'undefined') {
                        self.orderCreated = response.data.ordenCodigo;
                        self.orderCreatedId = response.data.ordenId;
                        self.orderPayLink = response.data.paylink;
                        self.orderPayLinkQr = response.data.paylink_qr;
                        if (typeof response.data.extra.factura !== 'undefined') {
                            self.facturaDetails = response.data.extra.factura;
                        }
                        if (typeof response.data.extra.recibo !== 'undefined') {
                            self.reciboDetails = response.data.extra.recibo;
                        }
                        self.$store.dispatch('OrderSaveCurrentOrder', self.orderCreatedId);
                        self.showOrderDetailsCreation = true;
                        self.showCardPaymentForm = false;
                        self.clearPos(true, false);
                    }
                    else {
                        API.showErrorAlert('Error, el procesador de pagos está redirigiendo inadecuadamente, por favor, contacte a servicio al cliente');
                    }
                }
                else {
                    API.showErrorAlert('Error al crear orden, el procesador de pagos no se encuentra disponible');
                }
            }
            else {
                if (typeof response.data.ordenId !== 'undefined') {
                    self.ordenCreadaId = response.data.ordenId;
                }

                if (typeof response.data.extra !== 'undefined' && typeof response.data.extra.error !== 'undefined') {
                    API.showErrorAlert(response.data.extra.error);
                }
                else {
                    API.showErrorAlert(response.msg);
                }
            }
        },
        resendInvoice() {
            const self = this;

            const dataSend = {
                idOrden: self.orderCreatedId,
                email: self.customEmailSendFactura,
            }
            // obtengo lsa categorías
            API.send('POST', 'orders/factura/enviar', dataSend,
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    store.dispatch('coreUnlockLoading');
                    store.dispatch('coreHideLoading');
                    API.showErrorNotify(response.msg);
                })
        },
        resendRecibo() {
            const self = this;

            const dataSend = {
                idOrden: self.orderCreatedId,
                email: self.customEmailSendRecibo,
            }
            // obtengo lsa categorías
            API.send('POST', 'orders/recibo/enviar', dataSend,
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    store.dispatch('coreUnlockLoading');
                    store.dispatch('coreHideLoading');
                    API.showErrorNotify(response.msg);
                })
        },
        printInvoice() {
            const self = this;

            if (this.BridgeAndroid.isOnTopWise()) {
                // obtengo lsa categorías
                API.send('GET', 'orders/factura/datos/' + self.orderCreatedId + '/fcUID', {},
                    function (response) {
                        if (response.status) {
                            //API.showSuccessNotify(response.msg);
                            self.BridgeAndroid.PrintFactura(response.data);
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (response) {
                        /*store.dispatch('coreUnlockLoading');
                        store.dispatch('coreHideLoading');
                        */
                        API.showErrorNotify(response.msg);
                    })
            }
            else {
                API.showErrorNotify('No es posible imprimir la factura en este dispositivo');
            }
        },
        qrScanned(data) {
            this.qrScannedResult = data;
            let token = /[^/]*$/.exec(this.qrScannedResult);
            this.token = (typeof token[0] !== 'undefined') ? token[0] : false;
            this.getFormInfo();
        },
        readerStart() {
            this.validatorStarted = true;
        },
        readerStop() {
            this.validatorStarted = false;
        },
        getFormInfo() {
            const self = this;
            if (self.token !== '') {
                API.send('GET', 'clients/validate-token-order/' + self.token, {},
                    function (response) {
                        if (response.status) {
                            self.formDetails = response.data;
                            self.OrderGetClientSelected.id = true;
                            self.OrderGetClientSelected.taxId = self.formDetails.taxId;
                            self.OrderGetClientSelected.taxId = self.formDetails.taxId;
                            self.OrderGetClientSelected.nombres = self.formDetails.nombres;
                            self.OrderGetClientSelected.apellidos = self.formDetails.apellidos;
                            self.OrderGetClientSelected.email = self.formDetails.email;
                            self.OrderGetClientSelected.telefono = self.formDetails.telefono;
                            self.qrToken = self.formDetails.token;
                        }
                        else {
                            self.qrToken = {};
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            }
        },
        enableFrEvents() {
            const self = this;
            // creamos una instancia de IfrEvents
            const eventHandler = window.IfrEvents.getInstance();
            eventHandler.captureData('POWERTRANZ_PAYMENT_REDIRECT', function (data, event_info) {
                const response = JSON.parse(data);

                // limpio el interval para cancelar el modal u oculto el modal
                if (self.isLoading) {
                    clearTimeout(self.isLoading);
                    self.isLoading = false;
                }
                store.dispatch('coreUnlockLoading');
                store.dispatch('coreHideLoading');

                /// sigo normal
                //console.log(response);
                if (response.status) {
                    self.showOrderDetailsCreation = true;
                    self.paymentOk = true;
                    self.clearPos(true, false);
                    API.showSuccessAlert('Orden pagada con éxito - Autorizacion:' + response.data.auth);
                }
                else {
                    API.showErrorAlert(response.msg);
                }
            });
            eventHandler.captureData('CARDINAL_PAYMENT_REDIRECT', function (data, event_info) {
                const response = JSON.parse(data);

                // limpio el interval para cancelar el modal u oculto el modal
                if (self.isLoading) {
                    clearTimeout(self.isLoading);
                    self.isLoading = false;
                }
                store.dispatch('coreUnlockLoading');
                store.dispatch('coreHideLoading');

                // sigo normal
                //console.log(response);
                if (response.status) {
                    self.showOrderDetailsCreation = true;
                    self.paymentOk = true;
                    self.clearPos(true, false);
                    API.showSuccessAlert('Orden pagada con éxito - Autorizacion:' + response.data.auth);
                }
                else {
                    API.showErrorAlert(response.msg);
                }
            });
        },
        increment(idProduct, quantity, precio) {
            quantity += 1;
            this.updatePosQuantity(idProduct, quantity, precio);

        },
        decrement(idProduct, quantity, precio) {
            if (quantity === 1) {
                return false;
            }
            else {
                quantity -= 1;
                this.updatePosQuantity(idProduct, quantity, precio);
            }
        },
        getAffiliations() {
            const self = this;
            if (self.token !== '') {
                API.send('GET', 'payments/affiliation-for-pay', {},
                    function (response) {
                        if (response.status) {
                            self.afiliaciones = response.data;
                            self.afiliaciones.forEach(element => {
                                if (parseInt(element['isPayment']) === 1) {
                                    self.afiliacionPago = element;
                                }
                            });
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            }
        },
    },
};
</script>
