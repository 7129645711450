<template>
    <div>
        <ficha-layout title="Medicina General" color="#575f64" :tabs="tabs" :tab-default=tabDefault enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #exploracion_fisica>
                <exploracion-fisica></exploracion-fisica>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
        </ficha-layout>
    </div>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


import FichaLayout from '../components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';

// componentes medicos
import Antecedentes from '../components/Antecedentes.vue';
import ExploracionFisica from '../components/ExploracionFisica.vue';
import Tratamiento from '../components/Tratamiento.vue';
import LaboratorioRadiologia from '../components/LaboratorioRadiologia.vue';
import PreClinica from '../components/PreClinica.vue';
import ImpresionClinica from '../components/ImpresionClinica.vue';
import Resumen from '../components/Resumen.vue';
import Adjuntos from "../components/Adjuntos.vue";

export default {
    components: {
        Adjuntos,
        Box,
        FichaLayout,
        Antecedentes,
        ExploracionFisica,
        Tratamiento,
        LaboratorioRadiologia,
        PreClinica,
        ImpresionClinica,
        Resumen,
    },
    props:{
    tabDefault: {
        default: 'preclinica',
        type: String
    }
    },
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                exploracion_fisica: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen físico',
                },
                /*consulta: {
                    icon: 'fas fa-stethoscope',
                    name: 'Medicina general',
                },*/
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
            },
            ginecologicos: {
                grupo_rh: '',
                menarquia: '',
                ultimo_papanicolau: '',
                trh: '',
                menopausia: '',
                papanicolau: '',
                otras_observaciones: '',
                parejas_sexuales: '',
                ciclo_mensual: '',
                planificacion_familiar: 'no',
                planificacion_familiar_tipo: '',
                observaciones: '',
            },
            obstetricos: {
                gesta: '',
                hijos_vivos: '',
                hijos_muertos: '',
                paras: '',
                abortos: '',
                cesareas: '',
                fecha_ultima_regla: '',
                fecha_probable_parto: '',
                edad_gestacional: '',
                planificacion_previa: 'no',
                confiabilidad: 'no',
                menarca: '',
                ciclos: '',
                fecha_ultimo_parto: '',
                peso: '',
                rn_con_mayor_peso: '',
                rn_con_menor_peso: '',
                complicaciones: 'no',
                complicaciones_descripcion: '',
                control_prenatal: 'no',
                numero_de_consultas: '1',
                lugar_de_control_prenatal: '',
                observaciones: '',
            },
            exploracion_fisica: {
                habitus_exterior: {
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                cabeza: {
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                faringe: {
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                cuello: {
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                torax: {
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    watch: {
    },
    mounted() {
        // this.loadItems();
    },
    methods: {
        ...mapActions({
        }),
        dayjs,
        Numbro,
    },
};

</script>
