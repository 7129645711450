import localDisk from 'src/localDisk';
import API from "src/core/Api";

export const orderState = {
    state: {
        clientSelected: localDisk.read('clientSelected'),
        posCart: localDisk.read('posCart'),
        shopCart: localDisk.read('shopCart'),
        posCartCurrentOrder: localDisk.read('posCartCurrentOrder'),
        categoryList: localDisk.read('categoryList'),
    },
    mutations: {
        PosCartSelectClient(state, data) {
            state.clientSelected = data;
            localDisk.write('clientSelected', state.clientSelected);
        },
        PosCartAddItem(state, data, disableNotify) {

            let tmpCart = localDisk.read('posCart');
            if (typeof tmpCart !== 'object') tmpCart = {};
            if (!disableNotify) disableNotify = false;

            if ( data ) {

                // si es monto directo, lo reemplazo
                if (data.id === '_DIRECT_AMOUNT_') {
                    data.id = `_DIRECT_AMOUNT_${Date.now()}`;
                    tmpCart[data.id] = false;
                }

                // si ya existe, sumo las cantidades
                if (typeof tmpCart[data.id] !== 'undefined' && tmpCart[data.id]) {

                    if (data.clearTotal) {
                        tmpCart[data.id]['quantity'] = 0;
                    }
                    // lo agrego al carrito
                    tmpCart[data.id]['quantity'] = parseInt((tmpCart[data.id]['quantity'] > 0) ? tmpCart[data.id]['quantity'] : 0) + 1;
                    //tmpCart[data.id]['product'] = data;
                }
                else {
                    // lo agrego al carrito
                    tmpCart[data.id] = {};
                    tmpCart[data.id]['quantity'] = 1;
                    tmpCart[data.id]['product'] = data;
                    tmpCart[data.id]['product']['subTotal'] = 0;
                }

                state.posCart = tmpCart;
                localDisk.write('posCart', state.posCart);
                API.showSuccessAlert('Producto o servicio seleccionado con éxito');
            }
        },
        PosCartChangeItemQuantity(state, data) {

            let tmpCart = localDisk.read('posCart');

            if (typeof tmpCart[data.id] !== 'undefined') {
                // lo agrego al carrito
                tmpCart[data.id]['quantity'] = parseInt(data.quantity);
                tmpCart[data.id]['product']['precio'] = parseFloat(data.precio);

                state.posCart = tmpCart;
                localDisk.write('posCart', state.posCart);
            }
        },
        PosCartRemoveItem(state, data) {

            let tmpCart = localDisk.read('posCart');

            if (typeof tmpCart[data.id] !== 'undefined') {
                // lo agrego al carrito
                delete tmpCart[data.id];

                state.posCart = tmpCart;
                localDisk.write('posCart', state.posCart);
            }
        },
        PosCartClear(state) {
            state.posCart = {};
            localDisk.write('posCart', state.posCart);
        },
        PosCartSaveCurrentOrder(state, data) {
            state.posCartCurrentOrder = data;
            localDisk.write('posCartCurrentOrder', state.posCartCurrentOrder);
        },

        // cart
        CartAddItem(state, data) {

            let tmpCart = localDisk.read('shopCart');
            if (typeof tmpCart !== 'object') tmpCart = {};

            if ( data ) {

                // si ya existe, sumo las cantidades
                if (typeof tmpCart[data.id] !== 'undefined') {

                    if (data.clearTotal) {
                        tmpCart[data.id]['quantity'] = 0;
                    }
                    // lo agrego al carrito
                    tmpCart[data.id]['quantity'] = parseInt((tmpCart[data.id]['quantity'] > 0) ? tmpCart[data.id]['quantity'] : 0) + 1;
                    tmpCart[data.id]['product'] = data;
                }
                else {
                    // lo agrego al carrito
                    tmpCart[data.id] = {};
                    tmpCart[data.id]['quantity'] = 1;
                    tmpCart[data.id]['product'] = data;
                }

                state.shopCart = tmpCart;
                localDisk.write('shopCart', state.shopCart);

                if (typeof data.disableNotify === 'undefined') {
                    API.showSuccessAlert('Producto agregado con éxito');
                }
            }
        },
        CartRefreshItem(state, data) {

            let tmpCart = localDisk.read('shopCart');
            if (typeof tmpCart !== 'object') tmpCart = {};

            if ( data ) {

                // si ya existe, sumo las cantidades
                if (typeof tmpCart[data.id] !== 'undefined') {
                    tmpCart[data.id]['product'] = data;
                }

                state.shopCart = tmpCart;
                localDisk.write('shopCart', state.shopCart);
            }
        },
        CartChangeItemQuantity(state, data) {

            let tmpCart = localDisk.read('shopCart');

            if (typeof tmpCart[data.id] !== 'undefined') {
                // lo agrego al carrito
                tmpCart[data.id]['quantity'] = parseInt(data.quantity);

                state.shopCart = tmpCart;
                localDisk.write('shopCart', state.shopCart);
            }
        },
        CartRemoveItem(state, data) {

            let tmpCart = localDisk.read('shopCart');

            if (typeof tmpCart[data.id] !== 'undefined') {
                // lo agrego al carrito
                delete tmpCart[data.id];

                state.shopCart = tmpCart;
                localDisk.write('shopCart', state.shopCart);
            }
        },
        CartClear(state) {
            state.shopCart = {};
            localDisk.write('shopCart', state.shopCart);
        },

        // categorias
        CategoryListSaveMutator(state, data) {
            state.categoryList = data;
            localDisk.write('categoryList', state.categoryList);
        },
    },
    getters: {
        OrderGetPosCartCurrentOrder: state => {
            if (!state.posCartCurrentOrder) {
                return 0;
            }
            else {
                return state.posCartCurrentOrder;
            }
        },
        OrderGetClientSelected: state => {
            if (!state.clientSelected) {
                return {};
            }
            else {
                return state.clientSelected;
            }
        },
        OrderGetPosCart: state => {

            // validate if posCart is corrupted or something
            if (state.posCart) {
                for (let item in state.posCart){
                    if (typeof state.posCart[item] !== 'undefined') {
                        if ((typeof state.posCart[item]['product'] === 'undefined') || (typeof state.posCart[item]['quantity'] === 'undefined')) {
                            state.posCart = {};
                            break;
                        }
                    }
                }
                return state.posCart;
            }
            else {
                return {};
            }
        },
        CartOpenStatus: state => {
            return state.CartOpen;
        },

        // cart store
        GetCart: state => {

            // validate if shopCart is corrupted or something
            if (state.shopCart) {
                for (let item in state.shopCart){
                    if (typeof state.shopCart[item] !== 'undefined') {
                        if ((typeof state.shopCart[item]['product'] === 'undefined') || (typeof state.shopCart[item]['quantity'] === 'undefined')) {
                            state.shopCart = {};
                            break;
                        }
                    }
                }
                return state.shopCart;
            }
            else {
                return {};
            }
        },

        // Categories
        CategoryListGet: state => {
            if (!state.categoryList) {
                return {};
            }
            else {
                return state.categoryList;
            }
        },
    },
    actions: {
        // POS
        OrderPosClientSelect: ({commit}, data) => {
            commit('PosCartSelectClient', data);
        },
        OrderPosClientClear: ({commit}) => {
            commit('PosCartSelectClient', {});
        },
        OrderAddProductToPos: ({commit, getters}, data) => {
            commit('PosCartAddItem', data);
        },
        OrderRemoveProduct: ({commit}, data) => {
            commit('PosCartRemoveItem', data);
        },
        OrderChangeProductQuantity: ({commit}, data) => {
            commit('PosCartChangeItemQuantity', data);
        },
        OrderClearProducts: ({commit}) => {
            commit('PosCartClear');
            commit('PosCartSaveCurrentOrder', null);
        },
        OrderSaveCurrentOrder: ({commit}, data) => {
            commit('PosCartSaveCurrentOrder', data);
        },

        // CART
        CartAddProduct: ({commit, getters}, data) => {
            commit('CartAddItem', data);
        },
        CartRefreshItem: ({commit, getters}, data) => {
            commit('CartRefreshItem', data);
        },
        CartRemoveProduct: ({commit}, data) => {
            commit('CartRemoveItem', data);
        },
        CartChangeProductQuantity: ({commit}, data) => {
            commit('CartChangeItemQuantity', data);
        },
        CartClearProducts: ({commit}) => {
            commit('CartClear');
            //commit('PosCartSaveCurrentOrder', null);
        },

        // Categories
        CategoryListSave: ({commit, getters}, data) => {
            commit('CategoryListSaveMutator', data);
        },
    }
}
