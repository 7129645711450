<template>
    <box title="Datos de paciente" icon="fa fa-user">
        <div class="row">
            <div v-if="tipoFicha !== 'veterinaria'"  class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Documento de identificación</label>
                    <input type="text" class="form-control" v-model="cuiPassport" @change="editClient"/>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label class="mr-sm-2">Género</label>
                    <select class="form-control" v-model="genero" @change="editClient">
                        <option value="M">
                            <template v-if="tipoFicha !== 'veterinaria'">Masculino</template>
                            <template v-else>Macho</template>
                        </option>
                        <option value="F">
                            <template v-if="tipoFicha !== 'veterinaria'">Femenino</template>
                            <template v-else>Hembra</template>
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-sm-3" v-if="tipoFicha === 'veterinaria'">
                <div class="form-group">
                    <label>Familia</label>
                    <select class="form-control" v-model="familia" @change="editClient">
                        <option value="canidae">Cánidos (caninos)</option>
                        <option value="felidae">Felidae (felinos: gatos, leones, tigres)</option>
                        <option value="bovidae">Bovidae (bovinos: vacas, toros)</option>
                        <option value="bovidae">Equidae (equinos: caballos, asnos, cebras)</option>
                        <option value="muridae">Muridae (ratones, ratas)</option>
                        <option value="suidae">Suidae (cerdos)</option>
                        <option value="cricetidae">Cricetidae (hámsters, cobayas)</option>
                        <option value="mustelidae">Mustelidae (comadrejas, nutrias)</option>
                        <option value="sciuridae">Sciuridae (ardillas)</option>
                        <option value="castoridae">Castoridae (castores)</option>
                        <option value="cervidae">Cervidae (cérvidos: ciervos, renos)</option>
                        <option value="giraffidae">Giraffidae (jirafas)</option>
                        <option value="hominidae">Hominidae (homínidos: humanos, chimpancés, orangutanes)</option>
                        <option value="cercopithecidae">Cercopithecidae (monos del Viejo Mundo)</option>
                        <option value="callitrichidae">Callitrichidae (tamarinos, titíes)</option>
                        <option value="hylobatidae">Hylobatidae (gibones)</option>
                        <option value="accipitridae">Accipitridae (águilas, gavilanes, buitres)</option>
                        <option value="falconidae">Falconidae (halcones, cernícalos)</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-sm-3" v-if="tipoFicha === 'veterinaria'">
                <div class="form-group">
                    <label>Raza</label>
                    <select class="form-control" v-model="raza" @change="editClient">
                        <template v-if="familia === 'canidae'">
                            <option value="mestizo">Mestizo</option>
                            <option value="labrador">Labrador</option>
                            <option value="pastor-aleman">Pastor Alemán</option>
                            <option value="bulldog-frances">Bulldog Francés</option>
                            <option value="golden-retriever">Golden Retriever</option>
                            <option value="beagle">Beagle</option>
                            <option value="boxer">Boxer</option>
                            <option value="chihuahua">Chihuahua</option>
                            <option value="doberman">Dóberman</option>
                            <option value="husky-siberiano">Husky Siberiano</option>
                            <option value="poodle">Poodle (Caniche)</option>
                            <option value="rottweiler">Rottweiler</option>
                            <option value="schnauzer">Schnauzer</option>
                            <option value="cocker-spaniel">Cocker Spaniel</option>
                            <option value="bulldog-ingles">Bulldog Inglés</option>
                            <option value="yorkshire-terrier">Yorkshire Terrier</option>
                            <option value="border-collie">Border Collie</option>
                            <option value="bichon-frise">Bichón Frisé</option>
                            <option value="pomerania">Pomerania</option>
                            <option value="dalmata">Dálmata</option>
                            <option value="san-bernardo">San Bernardo</option>
                        </template>
                        <template v-if="familia === 'felidae'">
                            <option value="mestizo">Mestizo</option>
                            <option value="siames">Siamés</option>
                            <option value="persa">Persa</option>
                            <option value="maine-coon">Maine Coon</option>
                            <option value="bengala">Bengala</option>
                            <option value="ragdoll">Ragdoll</option>
                            <option value="sphynx">Sphynx</option>
                            <option value="british-shorthair">British Shorthair</option>
                            <option value="abyssinian">Abisinio</option>
                            <option value="scottish-fold">Scottish Fold</option>
                            <option value="birmano">Birmano</option>
                            <option value="ragamuffin">Ragamuffin</option>
                            <option value="norwegian-forest">Bosque de Noruega</option>
                            <option value="ocicat">Ocicat</option>
                            <option value="siberiano">Siberiano</option>
                            <option value="egipcio">Mau Egipcio</option>
                            <option value="devon-rex">Devon Rex</option>
                            <option value="abyssinian">Abisinio</option>
                            <option value="exotico">Exótico</option>
                            <option value="manx">Manx</option>
                            <option value="somali">Somalí</option>
                        </template>
                        <template v-else>
                            <option value="otros">Otros</option>
                        </template>
                    </select>
                </div>
            </div>
            <div class="col-12 col-sm-3" v-if="tipoFicha === 'veterinaria'">
                <div class="form-group">
                    <label>Otras razas</label>
                    <input type="text" class="form-control" v-model="raza" @change="editClient"/>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Fecha nacimiento</label>
                    <date-picker v-model="fechaNacimiento" mode="date" @dayclick="editClient"  :timezone="'UTC'" >
                        <template #default="{ inputValue, inputEvents }">
                            <input :value="inputValue" v-on="inputEvents" class="form-control" @change="editClient"/>
                        </template>
                    </date-picker>
                </div>
            </div>

            <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Tipo de sangre</label>
                    <input type="text" class="form-control" v-model="datos_paciente.tipo_sangre" @change="saveMetaDatosPaciente"/>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Alergias</label>
                    <input type="text" class="form-control" v-model="datos_paciente.alergias_observaciones" @change="saveMetaDatosPaciente"/>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Tempreratura °C</label>
                   <div class="d-flex">
                    <input v-model="datos_paciente.temperatura" type="text" class="form-control w-50" aria-label="Text input with dropdown button" @change="saveMetaDatosPaciente">
                    <div class="input-group-append w-50">
                        <select class="form-control" v-model="datos_paciente.tempLocation" @change="saveMetaDatosPaciente">
                            <option value="rectal">Rectal</option>
                            <option value="axilar">Axilar</option>
                            <option value="timpano">Tímpano</option>
                            <option value="arterial">Arterial</option>
                            <option value="infrarrojo">Infrarrojo</option>
                    </select>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="form-group">
                    <label>Peso</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="datos_paciente.peso" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveMetaDatosPaciente"/>
                        <div class="input-group-append">
                            <span class="input-group-text">lb</span>
                        </div>
                        <template v-if="tipoFicha === 'pediatria'">
                            <input type="text" class="form-control" v-model="datos_paciente.pesoOz" v-maska data-maska="#000.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"  @change="saveMetaDatosPaciente"/>
                            <div class="input-group-append">
                                <span class="input-group-text">.oz</span>
                            </div>
                            <div class="input-group-append">
                                <span class="input-group-text">=</span>
                            </div>
                        </template>
                        <input type="text" class="form-control" v-model="pesoKg" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"  @change="calcularKgToLb"/>
                        <div class="input-group-append">
                            <span class="input-group-text">Kg</span>
                        </div>
                    </div>
                    <template v-if="tipoFicha === 'pediatria'">
                        <div class="text-muted">El peso del paciente es de {{datos_paciente.peso}} libras y {{datos_paciente.pesoOz}} onzas</div>
                    </template>
                </div>
            </div>
            <div class="col-12 col-sm-2">
                <div class="form-group">
                    <label>Talla</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="datos_paciente.talla" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveMetaDatosPaciente"/>
                        <div class="input-group-append">
                            <span class="input-group-text">cm</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3" v-if="tipoFicha === 'pediatria'">
                <div class="form-group">
                    <label>Circunferencia Encefálica</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="datos_paciente.circEncef" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveMetaDatosPaciente"/>
                        <div class="input-group-append">
                            <span class="input-group-text">cm</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3" v-if="tipoFicha !== 'veterinaria'">
                <div class="form-group">
                    <label>IMC (BMI)</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="BMI" disabled/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3" v-if="tipoFicha === 'veterinaria'">
                <div class="form-group">
                    <label>Características (color, manchas, etc)</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="caracteristicas"  @change="editClient"/>
                    </div>
                </div>
            </div>
        </div>
    </box>
    <box v-if="tipoFicha === 'veterinaria' || tipoFicha === 'pediatria' || tipoFicha === 'geriatria'" title="Datos del encargado responsable" icon="fa-solid fa-people-pulling">

        <div v-if="encargado" class="row">
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Nombres</label>
                    <input type="text" class="form-control" v-model="encargado.nombres" @change="editClient" placeholder="Nombres del encargado"/>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Apellidos</label>
                    <input type="text" class="form-control" v-model="encargado.apellidos" @change="editClient" placeholder="Apellidos del encargado"/>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Documento de identificación</label>
                    <input type="text" class="form-control" v-model="encargado.documento" @change="editClient" placeholder="Documento del encargado"/>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label class="mr-sm-2">Género</label>
                    <select class="form-control" v-model="encargado.genero" @change="editClient">
                        <option value="M">
                        Masculino
                        </option>
                        <option value="F">
                        Femenino
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Parentesco</label>
                    <input type="text" class="form-control" v-model="encargado.parentesco" @change="editClient" placeholder="Parentesco con del encargado con el paciente"/>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Número de teléfono</label>
                    <input type="text" class="form-control" v-model="encargado.email" @change="editClient" placeholder="Telefono del encargado"/>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Número de teléfono</label>
                    <input type="text" class="form-control" v-model="encargado.telefono" @change="editClient" placeholder="Telefono del encargado"/>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Ubicación</label>
                    <input type="text" class="form-control" v-model="encargado.ubicacion" @change="editClient" placeholder="Pais, ciudad y otros datos de ubicación"/>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label>Dirección</label>
                    <input type="text" class="form-control" v-model="encargado.direccion" @change="editClient" placeholder="Dirección del encargado"/>
                </div>
            </div>
        </div>
        <div v-else class="w-100 d-flex justify-content-center p-3">
            <p class=" text-muted">El paciente no tiene un encargado asignado</p>
        </div>
    </box>
    <box title="Motivo de consulta" icon="fa fa-question-circle">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label>Motivo de Consulta</label>
                    <textarea v-model="motivo_consulta.motivo_principal_consulta" class="form-control" placeholder="Escribe aquí" @change="saveMetaIndividual"></textarea>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label>Historial de la enfermedad actual (HPI)</label>
                    <textarea v-model="motivo_consulta.historial_de_la_enfermedad_actual_hpi" class="form-control" placeholder="Escribe aquí" @change="saveMetaIndividual"></textarea>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label>Fecha de inicio de síntomas</label>
                    <date-picker v-model="motivo_consulta.fecha_inicio_sintomas" mode="dateTime" @dayclick="saveMetaIndividual">
                        <template #default="{ inputValue, inputEvents }">
                            <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                        </template>
                    </date-picker>
                </div>
            </div>
        </div>
    </box>
    <box title="Signos Vitales" icon="fa fa-heartbeat">
        <div v-if="typeof GetMedicoFichaActive.metadata.preclinica_signos_v === 'undefined'">
            <div class="text-center text-danger">No tiene signos vitales ingresados</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Fecha y hora</th>
                    <th class="font-weight-bold">Presión arterial</th>
                    <th class="font-weight-bold">Frecuencia cardiaca</th>
                    <th class="font-weight-bold">Frecuencia respiratoria</th>
                    <th class="font-weight-bold">Saturación de oxígeno</th>
                    <th class="font-weight-bold">Temperatura</th>
                    <!--<th class="font-weight-bold">Peso</th>
                    <th class="font-weight-bold">Talla</th>
                    <th class="font-weight-bold">IMC</th>-->
                    <th class="font-weight-bold">Glicemia</th>
                    <th class="font-weight-bold">Estado de conciencia</th>
                    <th class="font-weight-bold">Dolor</th>
                    <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.preclinica_signos_v" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.fechaHora }}</td>
                        <td>{{ item.detail.presionArterial }}</td>
                        <td>{{ item.detail.frecuenciaCardiaca }}</td>
                        <td>{{ item.detail.frecuenciaRespiratoria }}</td>
                        <td>{{ item.detail.saturacionDeOxigeno }}</td>
                        <td>{{ item.detail.temperatura }}</td>
                        <!--<td>{{ item.detail.peso }} lb ({{lbToKg(item.detail.peso)}} kg)</td>
                        <td>{{ item.detail.talla }}</td>
                        <td>{{ item.detail.IMC }}</td>-->
                        <td>{{ item.detail.glicemia }}</td>
                        <td>{{ item.detail.estadoDeConciencia }}</td>
                        <td>{{ item.detail.dolor }}/10</td>
                        <td>
                            <i @click="editSignoVital(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                            <i @click="deletesignoVital(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5" v-if="showEdition">
            <div>
                <h5>Agregar Signos Vitales</h5>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Fecha y hora</label>
                        <input type="text" class="form-control" v-model="signoVital.detail.fechaHora" disabled/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Presión Arterial</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="signoVital.detail.presionArterial" v-maska data-maska="##0/##0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"/>
                            <div class="input-group-append">
                                <span class="input-group-text">mmHg</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Frecuencia Cardiaca</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="signoVital.detail.frecuenciaCardiaca" v-maska data-maska="##0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"/>
                            <div class="input-group-append">
                                <span class="input-group-text">bpm</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Frecuencia Respiratoria</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="signoVital.detail.frecuenciaRespiratoria" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"/>
                            <div class="input-group-append">
                                <span class="input-group-text">r/m</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Saturación de oxígeno</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="signoVital.detail.saturacionDeOxigeno" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"/>
                            <div class="input-group-append">
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Temperatura</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="signoVital.detail.temperatura" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"/>
                            <div class="input-group-append">
                                <span class="input-group-text">&#8451;</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Glicemia</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="signoVital.detail.glicemia" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"/>
                            <div class="input-group-append">
                                <span class="input-group-text">mg/dl</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Estado de conciencia</label>
                        <input type="text" class="form-control" v-model="signoVital.detail.estadoDeConciencia"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Dolor</label>
                        <Slider class="m-0 m-auto" v-model="signoVital.detail.dolor" :min="0" :max="10" tooltipPosition="bottom"/>
                    </div>
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="cancelEdit" class="btn btn-danger mr-3">Cancelar</button>
                <button @click="saveSignos" class="btn btn-primary mr-3">Guardar</button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="addNewSignoVital">
                <i class="fa fa-plus mr-2"></i>Agregar signos vitales
            </button>
        </div>
    </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';

import Box from "src/modules/medico/views/fichas/Box.vue";

// Componentes

export default {
    components: {
        DatePicker,
        Box,
        Slider
    },
    props: [
        'type',
    ],
    data() {
        return {
            // datos paciente
            tipoFicha: '',
            pesoKg: '',
            pesoOz: 0,
            BMI: '',
            datos_paciente: {},

            // motivo de consulta
            motivo_consulta: {},

            // signos vitales
            showEdition: false,
            signoVital: {},

            // cliente
            fechaNacimiento: '',
            cuiPassport: '',
            genero: '',
            familia: '',
            raza: '',
            caracteristicas: '',
        
            //encargado responsable
            encargado:null
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
        'datos_paciente.peso': function (value) {
            this.calcularLbToKg();
        },
        'datos_paciente.pesoOz': function (value) {
            this.calcularLbToKg();
        },
    },
    mounted() {

        this.tipoFicha = this.GetMedicoFichaActive.producto.fichaMedica;
        this.fechaNacimiento = this.GetMedicoFichaActive.cliente.fechaNacimiento;
        this.cuiPassport = this.GetMedicoFichaActive.cliente.cui_passport;
        this.genero = this.GetMedicoFichaActive.cliente.genero;
        this.familia = this.GetMedicoFichaActive.cliente.familia;
        this.raza = this.GetMedicoFichaActive.cliente.raza;
        this.caracteristicas = this.GetMedicoFichaActive.cliente.caracteristicas;
        this.encargado = this.GetMedicoFichaActive.cliente.mandated
        
        this.datos_paciente = {
            fecha_nacimiento: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.fecha_nacimiento : '',
            tipo_sangre: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre : '',
            peso: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.peso : '',
            pesoOz: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.pesoOz : 0,
            talla: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.talla : '',
            circEncef: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.circEncef : '',
            alergias_observaciones: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones : '',
            temperatura:(this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.temperatura : 0,
            tempLocation:(this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.tempLocation : 'rectal',
        };

        this.motivo_consulta = {
            motivo_principal_consulta: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.motivo_principal_consulta : '',
            historial_de_la_enfermedad_actual_hpi: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi : '',
            fecha_inicio_sintomas: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.fecha_inicio_sintomas : '',
        };
    },
    methods: {
        ...mapActions({
            OrderSaveMetadata: 'OrderSaveMetadata',
        }),
        dayjs,
        Numbro,
        // Motivo de consulta
        saveMetaDatosPaciente() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.motivo_consulta.id,
                    slug: 'datos_paciente',
                    detail: self.datos_paciente,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    //self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.calcularBMI();
            this.cancelEdit();
        },
        saveMetaIndividual() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.motivo_consulta.id,
                    slug: 'preclinica_motivo',
                    detail: self.motivo_consulta,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEdit();
        },

        // Signos vitales
        resetSignoVital() {
            this.signoVital = {
                id: 0,
                detail: {
                    fechaHora: dayjs().format('DD-MM-YYYY HH:mm'),
                    presionArterial: '',
                    frecuenciaCardiaca: '',
                    frecuenciaRespiratoria: '',
                    saturacionDeOxigeno: '',
                    temperatura: '',
                    peso: '',
                    pesoOz: '',
                    talla: '',
                    IMC: '',
                    glicemia: '',
                    estadoDeConciencia: '',
                    dolor: 0,
                }
            };
        },
        cancelEdit() {
            this.showEdition = false;
            this.resetSignoVital();
        },
        saveSignos() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.signoVital.id,
                    slug: 'preclinica_signos_v',
                    detail: self.signoVital.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEdit();
        },
        addNewSignoVital() {
            this.resetSignoVital();
            this.showEdition = true;
        },
        editSignoVital(item) {
            this.resetSignoVital();
            if (!item.detail.dolor) item.detail.dolor = 0; // arreglo el dolor por si viene null
            this.signoVital = item;
            this.showEdition = true;
        },
        deletesignoVital(item, key) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
        editClient() {

            const self = this;

            API.showConfirm('Antes de continuar', 'Esta acción editará la información del cliente en toda la plataforma, ¿desea continuar?', function (){
                const tmpClient = self.GetMedicoFichaActive.cliente;
                if (self.fechaNacimiento !== '') tmpClient.fechaNacimiento = self.fechaNacimiento;
                if (self.cuiPassport !== '') tmpClient.cui_passport = self.cuiPassport;
                if (self.genero !== '') tmpClient.genero = self.genero;
                if (self.familia !== '') tmpClient.familia = self.familia;
                if (self.raza !== '') tmpClient.raza = self.raza;
                if (self.caracteristicas !== '') tmpClient.caracteristicas = self.caracteristicas;
                if (self.encargado !== null) tmpClient.encargado = self.encargado;
                API.send('POST', 'clients/edit/', tmpClient, function (response) {
                        if (typeof response.status !== 'undefined') {
                            API.showSuccessAlert(response.msg);
                            self.emitter.emit("medico-ficha-refresh");

                        } else {
                            API.showErrorNotify('Error al guardar cliente, por favor intente de nuevo');
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }, function () {
                self.fechaNacimiento = self.GetMedicoFichaActive.cliente.fechaNacimiento;
                self.cuiPassport = self.GetMedicoFichaActive.cliente.cui_passport;
                self.genero = self.GetMedicoFichaActive.cliente.genero;
                self.familia = self.GetMedicoFichaActive.cliente.familia;
                self.raza = self.GetMedicoFichaActive.cliente.raza;
                self.caracteristicas = self.GetMedicoFichaActive.cliente.caracteristicas;
            })
        },


        // calcular peso
        lbToKg(value) {
            return parseFloat(parseFloat(value) * 0.45359237).toFixed(2);
        },
        calcularLbToKg() {
            if (this.tipoFicha === 'pediatria') {
                const lb = (this.datos_paciente.pesoOz / 16) + parseFloat(this.datos_paciente.peso);
                this.pesoKg = this.lbToKg(lb);
            }
            else {
                this.pesoKg = this.lbToKg(this.datos_paciente.peso);
            }
            //this.pesoOz = parseFloat(this.datos_paciente.peso) * 16;
            this.calcularBMI();
        },
        calcularKgToLb() {
            this.datos_paciente.peso = parseFloat(parseFloat(this.pesoKg) / 0.45359237).toFixed(2);
            this.saveMetaDatosPaciente();
        },
        calcularLbToOz() {
            this.datos_paciente.peso = parseFloat(parseFloat(this.pesoOz) / 16).toFixed(2);
            this.calcularLbToKg();
            this.saveMetaDatosPaciente();
        },
        calcularBMI() {

            let peso = 0;
            if (this.tipoFicha === 'pediatria') {
                peso = (this.datos_paciente.pesoOz / 16) + parseFloat(this.datos_paciente.peso);
            }
            else {
                peso = this.datos_paciente.peso;
            }

            const tallaPulgadas = this.datos_paciente.talla / 2.54;
            const BMI = (703 * peso) / (tallaPulgadas ** 2);
            this.BMI = parseFloat(BMI).toFixed(1);

            if (isNaN(BMI)) {
                this.BMI = 0;
            }

        }
    },
};

</script>
