<template>
    <div class="fichaMedicaTimeline" v-show="showTimeline">
        <div class="closeTimeline" @click="close">
            <i class="fa fa-times-circle"></i>
        </div>
        <div class="row">
            <div class="col-12">
                <h2 class="card-title pt-3 pb-2"> Historial del paciente</h2>
            </div>
            <div class="col-12 p-0">
                <div id="content">
                    <ul class="timeline-1">
                        <li class="event" v-for="item in history" :key="item.data.cita">
                            <h6 class="mb-3 pt-4 text-primary">{{item.fechaReserva}}</h6>
                            <div v-if="typeof item.data.metadata.producto !== 'undefined' && typeof item.data.metadata.producto.nombre !== 'undefined'">{{item.data.metadata.producto.nombre}}</div>
                            <div v-if="typeof item.data.metadata.preclinica_motivo !== 'undefined' && typeof item.data.metadata.preclinica_motivo.motivo_principal_consulta !== 'undefined'">{{item.data.metadata.preclinica_motivo.motivo_principal_consulta}}</div>
                            <a class="mdi mdi-file" :href="'/medic/ficha/'+item.data.reserva.id"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';

// Componentes

export default {
    components: {
        DatePicker
    },
    props: [
        'show',
    ],
    data() {
        return {
            showTimeline: false,
            history: {},
            dateCalendar: '',
            agendamientoCalendarAttr: [
                {
                    bar: true,
                    dates: [
                        new Date(2018, 0, 1), // Jan 1st
                        new Date(2018, 0, 10), // Jan 10th
                        new Date(2018, 0, 22), // Jan 22nd
                    ],
                },
                {
                    bar: 'red',
                    dates: [
                        new Date(2018, 0, 4), // Jan 4th
                        new Date(2018, 0, 10), // Jan 10th
                        new Date(2018, 0, 15), // Jan 15th
                    ],
                },
                {
                    bar: {
                        style: {
                            backgroundColor: 'brown',
                        },
                    },
                    dates: [
                        new Date(2018, 0, 12), // Jan 12th
                        new Date(2018, 0, 26), // Jan 26th
                        new Date(2018, 0, 15), // Jan 15th
                    ],
                },
            ]
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
        show: function(value) {
            this.showTimeline = value;
            this.loadItems();
        }
    },
    mounted() {

    },
    methods: {
        ...mapActions({
        }),
        dayjs,
        Numbro,
        loadItems() {
            const self = this;
            API.send('POST', 'medico/getHistory', {
                    'clienteId': self.GetMedicoFichaActive.cliente.id,
                }, function (response) {
                    self.history = response.data;
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        close() {
            this.$emit('close');
        }
    },
};

</script>
