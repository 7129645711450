<template>
    <!-- <layout :active="28" :title="true" :search="true">
    </layout> -->
    <div class="row dashboard-medic pt-3">
        <div class="col-12 col-xl-9">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-xxl-12">
                    <div class="card profile_chart transparent">
                        <div class="card-header p-0">
                            <div class="duration-option btn-group-sm">
                                <a id="today" class="c-pointer mb-2" @click="watch_active = 'today'; dataComponent.nombreTab = 'Hoy'" :class="dataComponent.active === 'today' && 'active'">Hoy</a>
                                <a id="ytd" class="c-pointer mb-2" @click="watch_active = 'ytd'; dataComponent.nombreTab = 'Ayer'" :class="dataComponent.active === 'ytd' && 'active'">Ayer</a>
                                <a id="one_month_actual" class="c-pointer mb-2" @click="watch_active = '1ma'; dataComponent.nombreTab = dataComponent.startUp.mesActual" :class="dataComponent.active === '1ma' && 'active'">{{ dataComponent.startUp.mesActual }}</a>
                                <a id="one_month" class="c-pointer mb-2" @click="watch_active = '1mp';  dataComponent.nombreTab = dataComponent.startUp.mesAnterior" :class="dataComponent.active === '1mp' && 'active'">{{ dataComponent.startUp.mesAnterior }}</a>
                                <a id="six_months" class="c-pointer mb-2" @click="watch_active = '6m'; dataComponent.nombreTab = '6 Meses'" :class="dataComponent.active === '6m' && 'active'">6 Meses</a>
                                <a id="one_year" class="c-pointer mb-2" @click="watch_active = '1y'; dataComponent.nombreTab = '1 año'" :class="dataComponent.active === '1y' && 'active'">1 año</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row info-cards">

                <div class="col-xl-4 col-lg-6 col-md-6 p-1">
                    <div class="widget-card">
                        <div class="widget-title mb-1 d-flex justify-content-between align-items-center">
                            <p class="text-primary">
                                <span><i style="color: var(--secondary-color);" class="las la-cash-register la-2x"></i></span>
                            </p>
                            <h5>Total cobrado, {{ dataComponent.nombreTab }} <div class="linea-dashboard"></div> </h5>
                        </div>
                        <div class="widget-info">
                            <div>
                                <h5 class="m-0">
                                <span class="text-muted">Total </span> {{ Numbro(dataComponent.reportes.ventas_finalizadas.total || 0).formatCurrency() }}
                            </h5>
                            <p class="text-success text-left m-0" v-if="dataComponent.reportes.estv.total > 0" >
                                <small class="text-muted">Porcentaje de pago </small><br/>
                                {{ Numbro(((dataComponent.reportes.ventas_finalizadas.total) * 100 / (dataComponent.reportes.estv.total))).formatCurrency() }}%
                                <span><i class="las la-arrow-up"></i></span>
                            </p>
                            </div>
                          
                            <div class="float-right text-right">
                                <ul>
                                    <li class="text-info">Efectivo:
                                        <span class="text-dark-gray">{{ Numbro((dataComponent.reportes.ventas_finalizadas.cash) ? dataComponent.reportes.ventas_finalizadas.cash : 0).formatCurrency() }}</span>
                                    </li>
                                    <li class="text-info">Tarjeta:
                                        <span class="text-dark-gray">{{ Numbro((dataComponent.reportes.ventas_finalizadas.card) ? dataComponent.reportes.ventas_finalizadas.card : 0).formatCurrency() }}</span>
                                    </li>
                                    <li class="text-info">Otro:
                                        <span class="text-dark-gray">{{
                                        Numbro(dataComponent.reportes.ventas_finalizadas.link || 0 + dataComponent.reportes.ventas_finalizadas.bank || 0).formatCurrency()
                                        }}</span>
                                    </li>
                                    <li class="text-info" v-if="dataComponent.enableMultiVendor">{{whiteLabelInfo.nombre}}:
                                        <span class="text-dark-gray">{{ Numbro((dataComponent.reportes.ventas.mv) ? dataComponent.reportes.ventas.mv : 0).formatCurrency() }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

              

                <div class="col-xl-4 col-lg-6 col-md-6 p-1 ">
                    <div class="widget-card">
                        <div class="widget-title mb-1 d-flex justify-content-between aling-items-center">
                            <p>
                                <span><i style="color: var(--secondary-color);" class="las la-procedures la-2x"></i></span>
                            </p>
                            <h5>Citas en progreso, {{ dataComponent.nombreTab }} <div class="linea-dashboard"></div> </h5>
                        </div>
                        <div class="widget-info">
                            <div>
                                <h5 class="m-0">
                                    <span class="text-muted">Total </span> {{ Numbro(dataComponent.reportes.estv.total || 0).formatCurrency() }}
                                </h5>
                                <p class="text-warning text-left m-0" v-if="dataComponent.reportes.estv.total > 0">
                                <small class="text-muted">Porcentaje sin pago </small><br/>
                                {{ Numbro(100 - ((dataComponent.reportes.ventas_en_progreso.pagadas || 0) * 100 / (dataComponent.reportes.estv.total || 0))).formatCurrency() }}%
                                <span><i class="fas fa-warning"></i></span>
                                </p>
                            </div>
                            <h5 class="text-warning"></h5>

                            <div class="float-right text-right">
                                <ul>
                                    <li class="text-success">Pagadas:
                                        <span class="text-dark-gray"> {{ Numbro(dataComponent.reportes.ventas_en_progreso.pagadas || 0).formatCurrency() }}</span>
                                    </li>
                                    <li class="text-warning">Sin pago:
                                        <span class="text-dark-gray"> {{ Numbro(dataComponent.reportes.ventas_en_progreso.sin_pago || 0).formatCurrency() }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
              

                <div class="col-12 col-xl-4 col-lg-6 col-md-6 p-1">
                    <div class="widget-card">
                        <div class="widget-title mb-1 d-flex justify-content-between aling-items-center">
                            <p class="text-secondary">
                                <span><i class="las la-calendar-times la-2x"></i></span>
                            </p>
                            <h5>Citas generadas, {{ dataComponent.nombreTab }} <div class="linea-dashboard"></div> </h5>
                        </div>
                        <div class="widget-info">
                            <h5 class="m-0">
                                <span class="text-muted">Total </span> {{ dataComponent.reportes.ordenes.total || 0 }}
                            </h5>
                            <div class="float-right text-right">
                                <ul>
                                    <li class="text-secondary">Clínica:
                                        <span class="text-dark-gray">{{ dataComponent.reportes.ordenes.pos || 0}}</span></li>
                                    <li class="text-secondary">Página web:
                                        <span class="text-dark-gray">{{ dataComponent.reportes.ordenes.tienda || 0 }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row card pacientes-atendidos">
                <div class="col-12 card-header">
                    <div class="d-flex justify-content-between px-1">
                    <h4 class="font-weight-bold pacientes-title">Pacientes atendidos</h4>
                    <p class="text-muted mb-0 mb-lg-0 time-info">{{dataComponent.nombreTab}}</p>
                    </div>
                    <div class="search">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Ingrese el paciente que desea buscar..." v-model="watch_nameToFind">
                        </div>
                    </div>
                </div>
                <div v-if="dataComponent.loadingTreated" class=" treatedLoader d-flex justify-content-center align-content-center p-4">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-if="dataComponent.treatedToShow && dataComponent.loadingTreated==false" class="col card-body card-treated">
                    <nav v-if="dataComponent.pagNum>1" aria-label="Pagination-nav">
                    <ul class="pagination justify-content-end">
                        <li class="page-item" @click="hadlerPage('prev')">
                        <a class="page-link" :class="disableArrows('left')"><i class="fa-solid fa-arrow-left"></i></a>
                        </li>
                        <li v-for="index in dataComponent.pagNum" class="page-item"><a class="page-link text-primary"  :class="activeShadow(index)" >{{ index }}</a></li>
                        <li class="page-item" @click="hadlerPage('next')">
                        <a class="page-link" :class="disableArrows('right')" ><i class="fa-solid fa-arrow-right"></i></a>
                        </li>
                    </ul>
                    </nav>
                    <table class="table">
                        <thead>
                        <tr class=" font-weight-bolder ">
                            <th  class=" font-weight-bolder " scope="col">Nombre</th>
                            <th  class=" font-weight-bolder " scope="col">Fecha</th>
                            <th  class=" font-weight-bolder " scope="col">Género</th>
                            <th  class=" font-weight-bolder " scope="col">Edad</th>
                            <th  class=" font-weight-bolder " scope="col">Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="client in dataComponent.treatedToShow" :key="client.index">
                            <td>{{ client.fullName}}</td>
                            <td>{{ client.time.start.slice(0, 10) }}</td>
                            <td>{{ client.genero }}</td>
                            <td>{{ client.edad }}</td>
                            <td class="d-flex gap-5 align-self-center text-center">
                                <!-- <router-link title="Ver ficha" class="mx-1 text-primary" :to="'/medic/ficha/'+client.id">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                                        <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"/>
                                    </svg>
                                </router-link>
                                <router-link title="Ver orden" class="mx-1 text-success" :to="'/medic/ficha/'+client.id">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                </router-link> -->
                                    <div title="Ir a consulta" class="p-1 text-primary cursor-pointer" @click.stop="goToPage(`/medic/ficha/${client.id}`)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                                            <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"/>
                                        </svg>
                                    </div>
                                    <div class="p-1 text-success cursor-pointer" title="Ver resumen" @click.stop="handlerResumeModal(client)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                        </svg>
                                    </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="dataComponent.loadingTreated==false" class="col card-body">
                    <p>No hay pacientes atendidos para {{ dataComponent.nombreTab.toLowerCase() }}</p>
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-3 card date-card">
            <div class="row">
                <div class="col-12">
                    <date-picker style="background-color: transparent"  v-model="watch_dateCalendar" mode="date" is-expanded  :attributes="dataComponent.agendamientoCalendarAttr"  />
                    <div class="row">
                        <div class="col-12">
                            <h2 class="card-title pt-3 pb-2"> Eventos del día</h2>
                        </div>
                        <div class="col-12 scroll-area card-body">
                            <div id="content">
                                <div v-if="dataComponent.loadingEventDay" class="d-flex justify-content-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <ul v-if="(dataComponent.toattend.length && dataComponent.dateCalendar != null && dataComponent.loadingEventDay===false)" class="timeline-1">
                                    <li v-for="client in dataComponent.toattend" class="event"  :data-date="client.time.start.split(' ')[1]" :key="client.id">
                                        <h6 class="mb-3 pt-4 text-primary">{{ client.title.split('(')[0] }}</h6>
                                        <p>{{  client.title.split(' - ')[1] }}</p>
                                        <router-link :to="'/medic/ficha/'+client.id">
                                            <i title="Ver ficha" class="mdi mdi-file mdi-18px"></i>
                                        </router-link>

                                    </li>

                                </ul>
                                <div v-else-if="dataComponent.loadingEventDay===false">No hay eventos para el dia {{ ((dataComponent.dateCalendar=== '') || (dataComponent.dateCalendar ===  null)) ? new Date().toISOString().slice(0, 10) : dataComponent.dateCalendar.toISOString().slice(0, 10) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showResumeModal" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <h3>Resumen de consulta</h3>

                            <div @click.stop="handlerResumeModal" class="p-1 d-flex justify-content-center align-items-center">
                                <i class="fa-solid fa-xmark fa-lg"></i>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <box title="Paciente" icon="fa fa-question-circle">
                            <div class="p-2 row">
                                <div class="col-3">
                                    <h6 class="text-primary">Nombre: </h6>
                                    <p class="text-dark">{{ dataResumeModal.fullName }}</p>
                                </div>
                                <div class="col-3">
                                    <h6 class="text-primary">Género: </h6>
                                    <p class="text-dark">{{ dataResumeModal.genero }}</p>
                                </div>
                                <div class="col-3">
                                    <h6 class="text-primary">Edad: </h6>
                                    <p class="text-dark">{{ dataResumeModal.edad }}</p>
                                </div>
                                <div class="col-3">
                                    <h6 class="text-primary">Fecha de atención: </h6>
                                    <p class="text-dark">{{ dataResumeModal.time.start }}</p>
                                </div>
                            </div>

                            <hr>
                        </box>
                        <box title="Motivo de consulta" icon="fa fa-question-circle" :contraction='true'>
                            <div v-if="dataResumeModal.metadata.preclinica_motivo.motivo_principal_consulta" class="p-3 w-100">
                                <p>{{dataResumeModal.metadata.preclinica_motivo.motivo_principal_consulta}}</p>
                            </div>
                            <div v-else class="p-3 w-100">
                                <div class="text-center text-danger">No se especificó un motivo de consulta</div>
                            </div>
                        </box>
                        <box title="Signos vitales" icon="fa fa-heartbeat" :contraction='true'>
                            <div class="p-3" v-if="typeof dataResumeModal.metadata.preclinica_signos_v === 'undefined'">
                                <div class="text-center text-danger">No tiene signos vitales ingresados</div>
                            </div>
                            <div class="p-3" v-else>
                                <table class="table align-middle m-0">
                                    <thead class="small flex-sm-nowrap font-weight-bold">
                                    <tr class="font-weight-bold small">
                                        <th class="font-weight-bold">Fecha y hora</th>
                                        <th class="font-weight-bold">Presión arterial</th>
                                        <th class="font-weight-bold">Frecuencia cardiaca</th>
                                        <th class="font-weight-bold">Frecuencia respiratoria</th>
                                        <th class="font-weight-bold">Saturación de oxígeno</th>
                                        <th class="font-weight-bold">Temperatura</th>
                                        <th class="font-weight-bold">Peso</th>
                                        <th class="font-weight-bold">Talla</th>
                                        <th class="font-weight-bold">IMC</th>
                                        <th class="font-weight-bold">Glicemia</th>
                                        <th class="font-weight-bold">Estado de conciencia</th>
                                        <th class="font-weight-bold">Dolor</th>
                                        <th class="font-weight-bold"></th>
                                    </tr>
                                    </thead>
                                    <tbody class="small">
                                    <tr v-for="item in dataResumeModal.metadata.preclinica_signos_v" :key="item.id">
                                        <template v-if="typeof item.detail !== 'undefined'">
                                            <td>{{ item.detail.fechaHora }}</td>
                                            <td>{{ item.detail.presionArterial }}</td>
                                            <td>{{ item.detail.frecuenciaCardiaca }}</td>
                                            <td>{{ item.detail.frecuenciaRespiratoria }}</td>
                                            <td>{{ item.detail.saturacionDeOxigeno }}</td>
                                            <td>{{ item.detail.temperatura }}</td>
                                            <td>{{ item.detail.peso }}</td>
                                            <td>{{ item.detail.talla }}</td>
                                            <td>{{ item.detail.IMC }}</td>
                                            <td>{{ item.detail.glicemia }}</td>
                                            <td>{{ item.detail.estadoDeConciencia }}</td>
                                            <td>{{ item.detail.dolor }}/10</td>
                                            <td>
                                                <i @click="editSignoVital(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                                                <i @click="deletesignoVital(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                                            </td>
                                        </template>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </box>
                        <box title="Impresión clínica" icon="fas fa-laptop-medical" :contraction='true'>
                            <div class="p-3" v-if="typeof dataResumeModal.metadata.impresion_clinica_cie10 === 'undefined' && typeof dataResumeModal.metadata.impresion_clinica === 'undefined'">
                                <div class="text-center text-danger">No tiene diagnostico ingresados</div>
                            </div>
                            <div  v-else class="w-100 p-3">
                                <div class="row px-4 py-1">
                                    <div class="form-group text-wrap overflow-hidden">
                                    <label class="font-weight-bold">Diagnóstico: </label>
                            
                                    <span v-for="item in dataResumeModal.metadata.impresion_clinica_cie10">
                                        <div class="text-wrap">{{ item }} </div>
                                    </span>
                                    <span v-for="item in dataResumeModal.metadata.impresion_clinica">
                                        <div class="text-wrap" v-html="nl2br(item)"></div>
                                    </span>

                                    </div>
                                </div>
                                <div v-if="dataResumeModal.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi" class="col-12 col-sm-6">
                                <div class="form-group text-wrap">
                                    <label class="font-weight-bold">Historial de la enfermedad actual (HPI): </label> {{dataResumeModal.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi}}
                                </div>
                                </div>
                            </div>
                        </box>
                        <box title="Tratamiento" icon="fa fa-comment-medical" :contraction='true'>
                            <div class="p-3" v-if="(typeof dataResumeModal.metadata.tratamiento !== 'undefined')">
                                <table v-if="(dataResumeModal.metadata.tratamiento.observaciones !== null)" class="table align-middle m-0">
                                    <thead class="small flex-sm-nowrap font-weight-bold">
                                    <tr class="font-weight-bold small">
                                        <th class="font-weight-bold">Observaciones</th>
                                    </tr>
                                    </thead>
                                    <tbody class="small">
                                    <tr v-for="(item, index) in dataResumeModal.metadata.tratamiento" :key="index">
                                        <template v-if="(typeof item !== 'undefined')">
                                            <td>{{item}}</td>
                                        </template>
                                    </tr>
                                    </tbody>
                                </table>
                                <div v-else class="text-center text-danger">No tiene datos en el tratamiento</div>
                            </div>           
                            <div class="p-3" v-else >
                                <div class="text-center text-danger">No tiene datos en el tratamiento</div>
                            </div>
                            <div v-if="(typeof dataResumeModal.metadata.tratamiento !== 'undefined')" class="mt-3 text-right p-3">
                                <div v-if="(dataResumeModal.metadata.tratamiento.observaciones !== null)" class="btn btn-primary" @click="printTratamiento"><i class="fas fa-print"></i> Imprimir tratamiento</div>
                            </div>
                        </box>
                        <box title="Receta" icon="fas fa-receipt" :contraction='true'>
                            <div class="p-3" v-if="(typeof dataResumeModal.metadata.tratamiento_receta === 'undefined')">
                                <div class="text-center text-danger">No tiene datos en receta</div>
                            </div>
                            <div v-else>
                                <table class="table align-middle m-0">
                                    <thead class="small flex-sm-nowrap font-weight-bold">
                                    <tr class="font-weight-bold small">
                                        <th class="font-weight-bold">Medicamento</th>
                                        <th class="font-weight-bold">Administración</th>
                                        <th class="font-weight-bold">Dosis y frecuencia</th>
                                        <th class="font-weight-bold">Observaciones</th>
                                    </tr>
                                    </thead>
                                    <tbody class="small">
                                    <tr v-for="item in dataResumeModal.metadata.tratamiento_receta" :key="item.id">
                                        <template v-if="(typeof item.detail !== 'undefined')">
                                            <td>{{ item.detail.medicamento }}</td>
                                            <td>{{ item.detail.administracion }}</td>
                                            <td>{{ item.detail.dosis }} cada {{ item.detail.cada }}, durante {{item.detail.durante}} {{item.detail.duranteTiempo}}</td>
                                            <td>{{ item.detail.observaciones }}</td>
                                        </template>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="(typeof dataResumeModal.metadata.tratamiento_receta !== 'undefined')"  class="mt-3 text-right p-3">
                                <div class="btn btn-primary" @click="printReceta"><i class="fas fa-print"></i> Imprimir receta</div>
                            </div>
                        </box>
                        <div class="modal-footer">
                           <div @click.stop="handlerResumeModal" class="btn btn-danger">
                            Cerrar
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
//import TimelineChart from "../components/charts/TimelineChart.vue";
import Layout from "/src/layout/LayoutLoader.vue";
import API from "/src/core/Api";
import Numbro from "numbro";
import Tools from "src/core/Tools";
import AndroidBridge from "src/core/AndroidBridge";
import {mapGetters} from "vuex";
import {DatePicker} from 'v-calendar';
import Box from './fichas/Box.vue';

//import Toster from "../components/Toster.vue";

export default {
    name: "Dashboard",
    components: {
        //TimelineChart,
        Layout,
        DatePicker,
        Box
        //Toster,
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    data() {
        return {

            dataComponent:{

                nameToFind:'',
                loadingEventDay:false,
                loadingTreated:false,
                enableMultiVendor: false,
                active: "today",
                nombreTab: 'Hoy',
                startUp: {},
                dateCalendar: '',
                agendamientoCalendarAttr: [
                    {
                        // dot: true,
                        key: 'today',
                        highlight: true,
                        dates: new Date(),

                    },
                    {

                        dot: true,
                        dates: [],
                    },
                    {

                        dot: 'red',
                        dates: [],
                    },
                ],
                reportes: {
                "ventas_finalizadas": {
                    "bank": 0,
                    "card":0,
                    "cash":0,
                    "link":0,
                    "total":0
                },
                "ventas_en_progreso": {
                    "pagadas": 0,   
                    "sin_pago":0
                },
                "ventas": {
                    "mv": 0,
                },
                "ordenes": {
                    "pos": 0,
                    "tienda": 0,
                    "mv": 0,
                    "total": 0
                },
                "estv": {
                    "pos": 0,
                    "tienda": 0,
                    "total": 0
                },
                },
                pagNum:0,
                pagActive:0,
                treatedToShow:[],
                treatedPerPage:[],
                treated:[],
                toattend:[],
                dateStart:new Date().toISOString().slice(0, 10),
                dateEnd:new Date().toISOString().slice(0, 10)
        },

        showResumeModal: false,
        dataResumeModal:{},

        watch_active: 'today',
        watch_dateCalendar: '',
        watch_nameToFind: '',

    };
    },
    methods: {
        Numbro,
        printReceta() {

        const self = this;
        API.send('POST', 'medico/receta/print', {
                orderId: self.dataResumeModal.ordenId,
            }, function (response) {
                
                window.open(response.data.url);
            
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        printTratamiento(){
        const self = this;
        API.send('POST', 'medico/tratamiento/print', {
                orderId: self.dataResumeModal.ordenId,
            }, function (response) {
                
                window.open(response.data.url);
            
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        setWithExpiry(key, value, ttl) {
        const now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        sessionStorage.setItem(key, JSON.stringify(item))
        },
        getWithExpiry(key) {
        const itemStr = sessionStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        if (now.getTime() > item.expiry) {
            sessionStorage.removeItem(key)
            return null
        }
        return item.value
        },
        goToPage(url){
            this.setWithExpiry('dataComponent',this.dataComponent,1 * 60 * 1000);
            this.$router.push(url);
        },
        handlerResumeModal(item){
            this.showResumeModal= !this.showResumeModal;
            if(this.showResumeModal){
                this.dataResumeModal=item;
            }else{
                this.dataResumeModal={};
            }
        },
        activeShadow(index){
            if(this.dataComponent.pagActive===index){
                return 'font-weight-bold';
            }else{
                return 'btn btn-primary'
            }
        },
        disableArrows(arrow){
            if (this.dataComponent.pagActive<=1 && arrow==='left') {
                return 'disabled text-muted';
            }

            if (this.dataComponent.pagActive>=this.dataComponent.pagNum && arrow==='right') {
                return 'disabled text-muted';
            }

            return 'text-primary';
        },
        hadlerPage(action){

            if (action==='next') {
                if(this.dataComponent.pagActive<(this.dataComponent.pagNum)){
                    this.dataComponent.pagActive=this.dataComponent.pagActive + 1;
                    this.dataComponent.treatedToShow=this.dataComponent.treatedPerPage[this.dataComponent.pagActive-1];
                }
                return;
            }else if (action==='prev'){
                if(this.dataComponent.pagActive>1){
                    this.dataComponent.pagActive=this.dataComponent.pagActive - 1;
                    this.dataComponent.treatedToShow=this.dataComponent.treatedPerPage[this.dataComponent.pagActive-1];
                }
                return;
            }else if(action!='prev' || action!='next' && action.typeof(Number)){
                this.dataComponent.pagActive=action;
                this.dataComponent.treatedToShow=this.dataComponent.treatedPerPage[this.dataComponent.pagActive-1];
            }
        },
        calcPagination(){
            this.dataComponent.treatedToShow=[];
            this.dataComponent.treatedPerPage=[];
            this.dataComponent.loadingTreated=true;
            let elementPerGroup=0;
            if(this.dataComponent.treated.length<150){
                this.dataComponent.pagNum=Math.ceil(this.dataComponent.treated.length/15);
                elementPerGroup=15;
            }else if(this.dataComponent.treated.length>150 && this.dataComponent.treated.length<300){
                this.dataComponent.pagNum=Math.ceil(this.dataComponent.treated.length/30);
                elementPerGroup=30
            }else{
                this.dataComponent.pagNum=Math.ceil(this.dataComponent.treated.length/500);
                elementPerGroup=50
            }


            for (let i = 0; i < this.dataComponent.treated.length; i += elementPerGroup) {
            const underGroup = this.dataComponent.treated.slice(i, i + elementPerGroup);
            this.dataComponent.treatedPerPage.push(underGroup);
            }
            const elementsOverflow = this.dataComponent.treated.length % elementPerGroup;
            if (elementsOverflow > 0) {
            const lastGroup = this.dataComponent.treated.slice(this.dataComponent.treated.length - elementsOverflow);
            this.dataComponent.treatedPerPage.push(lastGroup);
            }

            this.dataComponent.treatedToShow=this.dataComponent.treatedPerPage[0];
            this.dataComponent.pagActive=1;
            this.dataComponent.loadingTreated=false;
        },
        loadTreated(name){
            const self = this;
            self.dataComponent.loadingTreated=true;
         
            API.send('POST', 'orders/reservas/byName', {
                dateStart: self.dataComponent.dateStart,
                dateEnd: self.dataComponent.dateEnd,
                name: name ? name : "",
            },
            function (response) {
                    if (response.status) {
                        const now = new Date();
                        self.dataComponent.dateStart = response.data.dateStart;
                        self.dataComponent.dateEnd = response.data.dateEnd;
                        self.dataComponent.treated=[];
                        const eventosListTmp = [];


                        if (typeof response.data.detalle !== 'undefined') {

                            Object.keys(response.data.detalle).map(function (a) {

                                const evento = response.data.detalle[a];
                                const motivo = (typeof evento.metadata !== 'undefined' && typeof evento.metadata.preclinica_motivo !== 'undefined' && typeof evento.metadata.preclinica_motivo.motivo_principal_consulta !== 'undefined') ? evento.metadata.preclinica_motivo.motivo_principal_consulta : 'Sin motivo de asistencia especificado';
                                const cancelada = (parseInt(evento.estado) === 1);

                                eventosListTmp.push({
                                    id: evento.id,
                                    ordenId: evento.ordenId,
                                    fullName: evento.fullName,
                                    title: evento.nombre,
                                    edad: evento.edad,
                                    genero: evento.genero,
                                    with: evento.personalNombre,
                                    time: { start: evento.fechaInicial, end: evento.fechaFinal },
                                    color: cancelada ? "green" : "red",
                                    isEditable: cancelada,
                                    description: '<b>Motivo de cita:&nbsp;</b>' + motivo + (evento.entrecita ? '<br/> <b>Entre cita:&nbsp;</b> ' + evento.entrecitaMotivo : ''),
                                    disableDnD: [
                                        'month', 'week', 'day'
                                    ],
                                    disableResize: [
                                        'month', 'week', 'day'
                                    ],
                                    metadata:evento.metadata
                                })
                            })
                        }
                        eventosListTmp.forEach(event => {
                            const endTime = new Date(event.time.end);
                            if((now >= endTime)&& event.color==='green'){
                                self.dataComponent.treated.unshift(event);
                            }
                       })
                       self.dataComponent.loadingTreated=false;
                       self.calcPagination();
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })

        },
        loadToAttendMont(){

            const self = this;
            let dateStart = new Date();
            let dateEnd = new Date();
            if ((self.dataComponent.dateCalendar == '1970-01-01') || (self.dataComponent.dateCalendar == null) || (self.dataComponent.dateCalendar == 'undefined') || (self.dataComponent.dateCalendar == '')) {
                const now = new Date();

            // Primer día del año actual
            dateStart = new Date(now.getFullYear(), 0, 1).toISOString().slice(0, 10);

            // Último día del año actual
            dateEnd = new Date(now.getFullYear(), 11, 31).toISOString().slice(0, 10);

            }else{
             // Primer día del año actual
             dateStart = new Date(self.dataComponent.dateCalendar.getFullYear(), 0, 1).toISOString().slice(0, 10);

            // Último día del año actual
            dateEnd = new Date(self.dataComponent.dateCalendar.getFullYear(), 11, 31).toISOString().slice(0, 10);


            }

            API.send('POST', 'orders/reservas/list', {
                dateStart: dateStart,
                dateEnd: dateEnd,
                personal:[]//Agregar selector de medicos
            },
            function (response) {
                    if (response.status) {
                        const eventosListTmp = [];

                        if (typeof response.data.detalle !== 'undefined') {
                            Object.keys(response.data.detalle).map(function (a) {

                                const evento = response.data.detalle[a];
                                const cancelada = (parseInt(evento.estado) === 1);

                                eventosListTmp.push({
                                    time: { start: evento.fechaInicial, end: evento.fechaFinal },
                                    color: cancelada ? "green" : "red",
                                })
                            })
                        }
                        self.dataComponent.agendamientoCalendarAttr[1].dates=[];
                        self.dataComponent.agendamientoCalendarAttr[2].dates=[];
                        let dateGreen = [];
                        let dateRed = [];
                        eventosListTmp.forEach(event => {
                            if(event.color==='green'){
                                dateGreen.unshift(event.time.start.slice(0,10));
                            }else if(event.color==='red'){
                                dateRed.unshift(event.time.start.slice(0,10));

                            }
                       })

                       if (dateGreen.length){         
                        const uniqueGreen= new Set(dateGreen);
                        Array.from(uniqueGreen).map(date=>{
                            self.dataComponent.agendamientoCalendarAttr[1].dates.unshift(date+" 08:00")
                        });
                       }else if (dateRed.length) {

                        const uniqueRed= new Set(dateRed);
                        Array.from(uniqueRed).map(date=>{
                            self.dataComponent.agendamientoCalendarAttr[2].dates.unshift(date+" 08:00")
                        });
                       }
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })

        },
        loadToAttend(){
            const self = this;
            let calendarSelection = new Date();
            if ((self.dataComponent.dateCalendar == '1970-01-01') || (self.dataComponent.dateCalendar == null) || (self.dataComponent.dateCalendar == 'undefined') || (self.dataComponent.dateCalendar == '')) {
                const now = new Date();
                calendarSelection = now.toISOString().slice(0, 10);
            }else{
                calendarSelection = new Date(self.dataComponent.dateCalendar).toISOString().slice(0, 10)
            }

            API.send('POST', 'orders/reservas/list', {
                dateStart: calendarSelection,
                dateEnd: calendarSelection,
                personal:[]//Agregar selector de medicos
            },
            function (response) {
                    if (response.status) {
                        self.dataComponent.toattend=[];
                        const eventosListTmp = [];

                        if (typeof response.data.detalle !== 'undefined') {
                            Object.keys(response.data.detalle).map(function (a) {

                                const evento = response.data.detalle[a];
                                const motivo = (typeof evento.metadata !== 'undefined' && typeof evento.metadata.preclinica_motivo !== 'undefined' && typeof evento.metadata.preclinica_motivo.motivo_principal_consulta !== 'undefined') ? evento.metadata.preclinica_motivo.motivo_principal_consulta : 'Sin motivo de asistencia especificado';
                                const cancelada = (parseInt(evento.estado) === 1);
                                //TODO MODIFY BACKEND TO REQUETS

                                eventosListTmp.push({
                                    id: evento.id,
                                    title: evento.nombre,
                                    edad: evento.edad,
                                    genero: evento.genero,
                                    with: evento.personalNombre,
                                    time: { start: evento.fechaInicial, end: evento.fechaFinal },
                                    color: cancelada ? "green" : "red",
                                    isEditable: cancelada,
                                    description: '<b>Motivo de cita:&nbsp;</b>' + motivo + (evento.entrecita ? '<br/> <b>Entre cita:&nbsp;</b> ' + evento.entrecitaMotivo : ''),
                                    disableDnD: [
                                        'month', 'week', 'day'
                                    ],
                                    disableResize: [
                                        'month', 'week', 'day'
                                    ],
                                })
                            })
                        }

                        eventosListTmp.forEach(event => {
                            if(event.color==='green'){
                                self.dataComponent.toattend.unshift(event);
                            }
                       })
                       self.dataComponent.loadingEventDay=false;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        }
    },
    watch: {

        watch_active: function (val) {
            const self = this;
            self.dataComponent.active=val;
            self.dataComponent.loadingTreated=true;
            if (val==='today') {
                self.dataComponent.dateStart = new Date().toISOString().slice(0, 10);
                self.dataComponent.dateEnd = new Date().toISOString().slice(0, 10);

            }else if(val==='ytd'){
                const now = new Date();
                const yesterday = new Date();
                yesterday.setDate(now.getDate() - 1);

                self.dataComponent.dateStart= yesterday.toISOString().slice(0, 10);
                self.dataComponent.dateEnd= yesterday.toISOString().slice(0, 10);

            }else if (val==='1ma'){
                const now = new Date();
                // primer día del mes
                self.dataComponent.dateStart = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().slice(0, 10);

                // último día del mes
                self.dataComponent.dateEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().slice(0, 10);

            }else if (val==='1mp'){
                const now = new Date();
                const primerDiaDelMesAnterior = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                const ultimoDiaDelMesAnterior = new Date(now.getFullYear(), now.getMonth(), 0);
                self.dataComponent.dateStart = primerDiaDelMesAnterior.toISOString().slice(0, 10);
                self.dataComponent.dateEnd = ultimoDiaDelMesAnterior.toISOString().slice(0, 10);

            }else if (val==='6m'){
                const now = new Date();
                const semester = new Date()
                semester.setMonth(semester.getMonth() - 6);

                self.dataComponent.dateStart = semester.toISOString().slice(0, 10);
                self.dataComponent.dateEnd = now.toISOString().slice(0, 10);

            }else if (val==='1y'){
                const now = new Date();
                const year = new Date();
                year.setFullYear(year.getFullYear() - 1);
                self.dataComponent.dateStart = year.toISOString().slice(0, 10);
                self.dataComponent.dateEnd = now.toISOString().slice(0, 10);

            }


            if (val) {
                API.send('GET', 'reports/general/' + self.dataComponent.active, {},
                    function (response) {
                        self.dataComponent.reportes = Tools.parseObjectToFloat(response.data);

                    },
                    function (response) {
                        //self.invalidLink = true;
                        API.showErrorNotify(response.msg);
                    });

                }
                self.dataComponent.loadingTreated=false;
                self.loadTreated();


        },

        watch_dateCalendar: function (val) {
            this.dataComponent.dateCalendar=val;
            this.dataComponent.loadingEventDay=true;
            this.loadToAttend();
            this.loadToAttendMont();
        },

        watch_nameToFind: function (val){
            this.dataComponent.nameToFind=val;
            this.loadTreated(val);
        }

    },
    mounted() {
        let cacheDataComponent = this.getWithExpiry('dataComponent');
        if  (cacheDataComponent != null){
            this.dataComponent = cacheDataComponent;
        }else{
            this.loadToAttend();
            this.loadTreated();
            this.loadToAttendMont();
        

            const self = this;

            if (typeof this.whiteLabelInfo.multiVendor !== 'undefined' && this.whiteLabelInfo.multiVendor !== '') {
                this.dataComponent.enableMultiVendor = true;
            }

            //API.showErrorAlert('Prueba de notificación', 'Esta es un error');

            API.send('GET', 'reports/configuracion/', {},
                function (response) {
                    self.dataComponent.startUp = response.data;
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })

            API.send('GET', 'reports/general/' + self.dataComponent.active, {},
                function (response) {
                    self.dataComponent.reportes = Tools.parseObjectToFloat(response.data);
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })

            this.androidBridge = new AndroidBridge();

            // Salida en Clínica TOP WISE
            if (this.androidBridge.isOnTopWise()) {
                this.androidBridge.TopWiseCloseApp('http://localhost/dashboard');
            }
        }
    },

};
</script>

<style scoped>
.Pagination-nav{
    display: flex;
    align-items: center;
}

.active-shadow{
    background-color: rgb(188, 188, 188);
}

.pacientes-title{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.time-info{
    border-left: 1px solid rgba(136, 136, 136, 0.326);
    padding-left: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.search{
    min-width: 300px;
}

.input-search{
    width: 100%;
}

.dashboard-medic{
    width: 100%;
    height: 100%;
}
.treatedLoader{
    min-height: 100px;
}
.card-body{
    height: fit-content;
    max-height: 100%;
}
/* #content{
    height: fit-content;
} */
.info-cards{
    margin: 0 1px;
}

.pacientes-atendidos{
    padding-right: 10px;
    margin: 0 1px;
    margin-bottom: 20px;
}

.date-card{
    min-height: calc( 100vh - 60px);

}

.timeline-1 {
    border-left: 3px solid var(--primary-color);
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;

    margin: 0 auto;
    position: relative;
    padding: 50px;
    list-style: none;
    text-align: left;
    max-width: 100%;

}

@media (max-width: 767px) {
    .timeline-1 {
        max-width: 100%;
        padding: 25px;
    }
}

.timeline-1 .event {
    border-bottom: 2px dashed var(--primary-color);
    padding-bottom: 16px;
    margin-bottom: 20px;
    padding-left: 5px;
    position: relative;
    background: rgba(var(--primary-color),var(--primary-color),var(--primary-color),0.5%);
    border-radius: 0px;
    transition: 0.3s;
}

.timeline-1 .event:hover {
    border-bottom: 3px dashed rgba(44, 189, 47, 0.679);
    border-radius: 5px;
}
.linea-dashboard {
    padding-top: 10%;
    border-bottom: 2px solid var(--primary-color);
    width: 100%;
}

@media (max-width: 767px) {
    .timeline-1 .event {
        padding-top: 20px;
    }
}

.timeline-1 .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
}

.timeline-1 .event:before,
.timeline-1 .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline-1 .event:before {
    content: attr(data-date);
    text-align: right;
    font-weight: 100;
    font-size: 0.9em;
}

@media (max-width: 767px) {
    .timeline-1 .event:before {
        left: 0px;
        text-align: left;
    }
}

.timeline-1 .event:after {
    -webkit-box-shadow: 0 0 0 3px var(--primary-color);
    box-shadow: 0 0 0 3px var(--primary-color);
    left: -55.8px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: "";
    top: 0px;
}

@media (max-width: 767px) {
    .timeline-1 .event:after {
        left: -31.8px;

    }
}

.card-treated{
    padding-top: 5px !important;
}
</style>