<template>
    <setting-layout :active="41">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de clientes</h4>
                <div>
                    <router-link to="/client/0" class="btn btn-primary btn-sm mr-2">
                        <i class="fa fa-plus-circle"></i> Agregar
                    </router-link>
                    <router-link to="/clients/add/massive" class="btn btn-primary btn-sm mr-2">
                        <i class="fa fa-plus-circle"></i> Carga masiva
                    </router-link>

                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h6 class="w-100 px-1 py-3 font-weight-bold">Buscar por:</h6>
                        <div class="row">
                            <div class="col-12 col-lg-10">
                                <div class="row">
                                    <div class="col-12 col-lg-3">
                                        <input type="text" placeholder="DPI o Pasaporte" v-model="filterDPI" class="form-control mb-1" v-on:keyup.enter="doSearch">
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <input type="text" placeholder="Nombre" v-model="filterNombre" class="form-control mb-1" v-on:keyup.enter="doSearch">
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <input type="text" placeholder="Apellido" v-model="filterApellido" class="form-control mb-1" v-on:keyup.enter="doSearch">
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <input type="text" placeholder="Correo Electrónico" v-model="filterEmail" class="form-control mb-1" v-on:keyup.enter="doSearch">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2 text-right">
                                <button type="button" class="btn btn-primary w-100" @click="doSearch"><i class="fa fa-search mr-2"></i> Buscar</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="showHelper" class="d-flex align-items-center px-3 py-2 text-primary">
                        <i class="fa-regular fa-lightbulb mr-2"></i><small>Es necesario ingresar datos para la busqueda</small>
                    </div>
                    <div  v-if="itemList.length > 0" class="col-12">
                        <nav aria-label="navigation" class="px-1 py-2">
                            <ul class="pagination">
                                <li class="page-item" v-for="item in pagination" :key="'page' + item">
                                    <a v-if="!isNaN(parseFloat(item))" @click="pageSelected = item; loadItems(); " :class="pageSelected == item?'page-link cursor-pointer page-link-active':'page-link cursor-pointer'">{{ item }}</a>
                                    <div v-else class="page-link">{{ item }}</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="form">
                    <div v-if="itemList.length === 0" class="d-flex justify-content-around align-items-center" >
                        <div class="text-muted d-flex justify-content-center align-items-center mx-5 my-1 py-5 border-top w-75">
                            <i class="fa-solid fa-circle-exclamation"></i>
                            <p class="m-0 ml-2">No se encontraron resultados</p>
                        </div>
                    </div>
                    <ul v-else>
                        <li v-for="item in itemList" :key="item.id" class="h-50">
                            <div class="listItem">
                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        <div>
                                            <h5 class="mt-0 mb-1">{{item.nombres}} {{item.apellidos}}</h5>
                                            <div><b>Código:</b> {{ item.id }}</div>
                                            <div><b>Correo electrónico:</b> {{ item.email }}</div>
                                            <div class="text-primary" v-if="item.edad"><b>Edad:</b> {{ item.edad }} años</div>
                                            <div v-if="item.cui_passport"><b>Documento de identificación</b> {{ item.cui_passport }}</div>
                                            <div>
                                                <b>Género:</b>
                                                <span v-if="item.genero === 'M'">Hombre</span>
                                                <span v-else-if="item.genero === 'F'">Mujer</span>
                                                <span v-else>No especificado</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 text-right">
                                        <div class="edit-options" title="Ver detalles">
                                            <div class="group">
                                                <!-- <router-link class="p-0 m-0" :to="'/client/'+item.id"> -->
                                                        <i @click="gotoPage(item.id)" class="fa-solid fa-circle-info text-primary cursor-pointer p-1"></i>
                                                
                                                        <i @click="deleteClient(item.id)" class="fa-solid fa-trash-can text-danger cursor-pointer p-1"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </setting-layout>
</template>
<style scoped>
.edit-options{
    font-size: 20px;
   
}´
.group{
    display: flex;
    justify-content: center;
}

</style>

<script>
import settingLayout from "../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";
import dayjs from "dayjs";

export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            showHelper:false,
            itemList: {},
            pagination: {},
            pageSelected: 1,
            limit: 10,
            filterDPI: '',
            filterNombre: '',
            filterApellido: '',
            filterEmail: '',
            searchTerm: 0
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    mounted() {
        this.loadItems();
    },
    watch: {
    },
    methods: {
        dayjs,
        doSearch() {
            if( this.filterDPI===''&& this.filterNombre==='' && this.filterApellido==='' && this.filterEmail===''){
                return   this.showHelper=true;
            }
            const self = this;
            self.pageSelected = 1;
            self.limit = 20;
            self.loadItems();
            this.showHelper=false;
        },
        loadItems() {
            const self = this;

            API.send('POST', 'clients/get-list', {
                    page: self.pageSelected,
                    perPage: self.limit,
                    filterDPI: self.filterDPI,
                    filterNombre: self.filterNombre,
                    filterApellido: self.filterApellido,
                    filterEmail: self.filterEmail,
                },
                function (data) {
                    if (data.status) {
                        self.itemList = data.data.clientes;
                        self.pagination = data.data.paginas;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        }, 
        deleteClient(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar este cliente?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'clients/delete/' + id, {},
                    function (data) {
                        API.showSuccessNotify(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            })
        },
        gotoPage(id){
            this.$router.push(`/client/${id}`);
        }
    },
};
</script>
