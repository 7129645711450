<template>
<layout>
    <div class="content-body">
        <div class="mb-80">
            <div class="container h-100">
                <form id="formData" @submit="save" method="post">
                    <div class="row justify-content-center h-100 align-items-center">
                        <div class="col-xl-12 col-md-12">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                <router-link to="/products">
                                                    <i class="back fa fa-arrow-circle-left"></i>
                                                </router-link>
                                                Editar producto o servicio
                                            </h4>
                                            <a class="btn btn-primary float-right" href="/products/edit/new">
                                                <i class="fa fa-plus"></i> Agregar
                                            </a>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Nombre</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí"
                                                           v-model="nombre"/>
                                                </div>

                                                <div class="form-group col-12 col-sm-6">
                                                    <label class="mr-sm-2">
                                                        SKU
                                                        <i class="fa fa-question-circle"
                                                           v-tooltip="'Código de identificación del producto'"></i>
                                                    </label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="sku"/>
                                                </div>

                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Descripción</label>
                                                    <div>
                                                        <textarea v-model="descripcion" class="form-control"></textarea>
                                                        <!--<TextEditor  :options="editorOption"  v-model:editor-value="descripcion"/>-->
                                                    </div>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Palabras clave o etiquetas</label>
                                                    <div>
                                                        <vue3-tags-input :tags="tags"
                                                            placeholder="Ingresa palabras clave"
                                                            @on-tags-changed="handleChangeTag"/>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-md-6 form-group">
                                                    <label class="mr-sm-2">Precio normal</label>
                                                    <input type="number" class="form-control" placeholder="Escribe aquí"
                                                           v-model="precio"/>
                                                </div>
                                                <div class="col-12 col-md-6 form-group">
                                                    <label class="mr-sm-2">Precio ofertado</label>
                                                    <input type="number" class="form-control" placeholder="Escribe aquí"
                                                           v-model="precioOnSale"/>
                                                </div>
                                                <div class="col-12 pt-3" v-if="token === ''">
                                                    <hr/>
                                                    <div class="text-center">
                                                        <button type="button" class="btn btn-primary mr-2" @click="preSave">
                                                            Guardar
                                                        </button>
                                                        <router-link to="/products">
                                                            <button type="button" class="btn btn-danger">Cancelar</button>
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Configuración
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row" v-if="!AuthGetUserInfo.wlAdmin && enableMultiVendor">
                                                <div class="form-group col-12 mb-5">
                                                    <div class="mb-3">
                                                        <h4>Mostrar en {{whiteLabelInfo.domain}}</h4>
                                                    </div>
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="showMultiVendor"
                                                            :checked="showMultiVendor"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label"> Mostrar mi producto o servicio en centro comercial virtual</span>
                                                    </label>
                                                    <div class="mt-4" v-if="showMultiVendor">
                                                        <div class="mb-2">
                                                            Al desplegarse en el centro comercial virtual, tu producto utilizará las categorías permitidas por {{whiteLabelInfo.domain}}. Selecciona las categorías para tu producto:
                                                        </div>
                                                        <multiselect v-model="categoriaMvSelected" :options="categoriasMv" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona las categorías" :multiple="true"></multiselect>
                                                    </div>
                                                    <div class="mt-4" v-if="showMultiVendor">
                                                        <div class="mb-2">
                                                            También puedes agregar marcas de {{whiteLabelInfo.domain}}. Si no aparece pídele a tu contacto más cercano para que se cree la marca con el logotipo oficial
                                                        </div>
                                                        <multiselect v-model="marcasMvSelected" :options="marcasMv" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona las categorías" :multiple="true"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div v-if="!AuthGetUserInfo.wlAdmin && enableMultiVendor" class="col-12">
                                                    <h4>Información de producto</h4>
                                                </div>
                                                <div class="form-group col-12 col-sm-6">
                                                    <label class="mr-sm-2">
                                                        Identificador de terceros
                                                        <i class="fa fa-question-circle"
                                                           v-tooltip="'Puedes guardar aquí el identificador que relaciona este producto con sistemas de terceros'"></i>
                                                    </label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí"
                                                           v-model="erpId"/>
                                                </div>
                                                <div class="form-group col-12 col-sm-6">
                                                    <label class="mr-sm-2">Slug</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí"
                                                           v-model="slug"/>
                                                </div>
                                            </div>
                                            <div class="row" v-if="id > 0">
                                                <div class="col-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                               v-model="enableProduct" :checked="enableProduct"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">Habilitar producto</span>
                                                    </label>
                                                </div>
                                                <div class="col-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                               v-model="showInStore" :checked="showInStore"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">Mostrar en tienda</span>
                                                    </label>
                                                </div>
                                                <div class="col-6 mt-4">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                               v-model="destacado" :checked="destacado"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">Destacado</span>
                                                    </label>
                                                </div>
                                                <div class="col-6 mt-4 pb-4">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                               v-model="nuevo" :checked="nuevo"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">Nuevo</span>
                                                    </label>
                                                </div>
                                                <div class="col-6 mb-3">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="onSale"
                                                               :checked="onSale"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label"> Habilitar precio de oferta</span>
                                                    </label>
                                                </div>
                                                <div class="col-6 mb-1">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                               v-model="precioConveniencia"
                                                               :checked="precioConveniencia"
                                                               @click="precioConvenienciaEnable"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                                Precio a conveniencia o abierto
                                                                <i class="fa fa-question-circle"
                                                                   v-tooltip="'Los precios a conveniencia permiten que los clientes puedan decidir el monto a pagar por este producto o servicio, suele utilizarse para recepción de donaciones u otros. Esta configuración se desactiva para productos pagados a través de una orden.'"></i>
                                                            </span>
                                                    </label>
                                                </div>
                                                <div v-if="token !== ''" class="col-12">
                                                    <hr class="mt-4">
                                                    <h5>Link de pago</h5>
                                                    <span class="text-muted">Puedes enviar este enlace para que tus clientes compren o se suscriban a este producto</span>
                                                    <div class="text-center mt-2">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12 col-md-8">
                                                                <div>
                                                                    <small>
                                                                        <a class="text-primary" :href="payLink"
                                                                           target="_blank">{{ payLink }}</a>
                                                                    </small>
                                                                </div>
                                                                <div class="btn btn-sm btn-primary mt-3"
                                                                     @click="copyLink(payLink)">
                                                                    <i class="fa fa-copy"></i> Copiar enlace
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-12 col-md-4">
                                                                <img :src="payLinkQR" style="max-width: 100px"/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card" v-if="id > 0 && agendable">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Médico
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row mb-4">
                                                <div class="col-12">
                                                    <label>
                                                        Si el producto es una especialidad médica, seleccione la ficha clínica correspondiente.
                                                    </label>
                                                    <select v-model="fichaMedica" class="form-control">
                                                        <optgroup label="Médico">
                                                            <option value="medicina_general">Medicina General</option>
                                                            <option value="urgencia">Urgencia</option>
                                                            <option value="odontologia">Odontología</option>
                                                            <option value="pediatria">Pediatría</option>
                                                            <option value="ginecologia">Ginecología</option>
                                                            <option value="nutricion">Nutrición</option>
                                                            <option value="urologia">Urología</option>
                                                            <option value="podologia">Podología</option>
                                                            <option value="traumatologia">Traumatología</option>
                                                            <option value="somnologia">Somnología</option>
                                                            <option value="fisioterapia">Fisioterapia</option>
                                                            <option value="dermatologia">Dermatología</option>
                                                            <option value="psicologia">Psicología</option>
                                                            <option value="geriatria">Geriatría</option>
                                                            <option value="tanatologia">Tanatología</option>
                                                            <option value="prenatal">Prenatal</option>
                                                        </optgroup>
                                                        <optgroup label="Veterinario">
                                                            <option value="veterinaria">Veterinaria</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card" v-if="id > 0">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                <img src="staticAssets/formBuilder/docsplus.png" style="max-width: 80px" alt="Docs+">
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row mb-4">
                                                <div class="col-12">
                                                    <label>
                                                        Generar documento personalizado después de la compra. Los documentos generados se envían automáticamente al correo electrónico del cliente.
                                                    </label>
                                                    <select v-model="formularioSelected" class="form-control">
                                                        <option v-for="item in docsForms" :value="item.id" :key="'fdoc'+item.id">{{ item.descripcion }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<div class="card" v-if="token !== ''">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Enlace de pago o suscripción
                                            </h4>
                                        </div>
                                        <div class="card-body">

                                            <div>
                                                Incrustación de punto de pago en sitios web de terceros.
                                            </div>
                                            <div class="embedCode">
                                                <code>
                                                    <pre>
&lt;!&#45;&#45; PASTE IN HEADER &#45;&#45;&gt;
&lt;!&#45;&#45; Any Subscriptions Payment Embed - Form &#45;&#45;&gt;
&lt;script async src="https://anysubscriptions.com/embed/enterprise.js"&gt;&lt;/script&gt;
&lt;script&gt;
window.AnYsB = window.AnYs | {};
window.onload = function () {
(function() {
    window.AnYs.enable();
    window.AnYs.addDefaultStyles();
    window.AnYs.AddFunction('embedPayForm','AnySubscriptionsEmbedFormTarget',
        '{{ token }}'
    );
})();
}
&lt;/script&gt;

&lt;!&#45;&#45; PASTE IN PLACE FOR EMBED&#45;&#45;&gt;
&lt;!&#45;&#45; Pay form container&#45;&#45;&gt;
&lt;div id="AnySubscriptionsEmbedFormTarget" class="AnySubscriptionsEmbed"&gt;&lt;/div&gt;
                                                    </pre>
                                                </code>
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="card" v-if="id > 0">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Tipo y categorías
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <div>
                                                        <label class="mr-sm-2">Categorías</label>
                                                        <multiselect v-model="categoriaSelected" :options="categorias" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona las categorías" :multiple="true"></multiselect>
                                                    </div>
                                                </div>
                                                <div class="form-group col-12">
                                                    <div>
                                                        <label class="mr-sm-2">Marcas</label>
                                                        <multiselect v-model="marcaSelected" :options="marcas" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona las marcas" :multiple="true"></multiselect>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="virtual"
                                                               :checked="virtual"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label"> Virtual o intangible</span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                               v-model="agendable"
                                                               :checked="agendable"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label"> Agendable</span>
                                                    </label>
                                                </div>

                                                <div v-show="agendable" class="col-12 col-sm-6">
                                                    <label class="mr-sm-2 pt-3">
                                                        Tiempo de reserva
                                                        <i class="fa fa-question-circle ml-2"
                                                           v-tooltip="'Acá se configura el tiempo que durará una cita, el formato es hh:mm:ss'"></i>
                                                    </label>
                                                    <div class="form-group">
                                                        <input class="form-control" type="text" v-model="tiempoReserva"  v-maska data-maska="##:##:##" placeholder="00:00:00"/>
                                                        <div class="text-muted">Formato: Horas:Minutos:Segundos</div>
                                                    </div>
                                                </div>
                                                <div v-show="agendable" class="col-12 col-sm-6">
                                                    <br>
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                            v-model="aDomicilio"
                                                            :checked="aDomicilio"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label"> Cita a domicilio</span>
                                                    </label>
                                                </div>
                                                <div class="form-group col-12" v-show="agendable">
                                                    <div>
                                                        <label class="mr-sm-2">Personal</label>
                                                        <multiselect v-model="asignaciones"
                                                                     :options="personal"
                                                                     track-by="id" label="name"
                                                                     :close-on-select="false"
                                                                     :clear-on-select="false"
                                                                     :preserve-search="true"
                                                                     placeholder="Selecciona a tu personal"
                                                                     :multiple="true">

                                                        </multiselect>
                                                    </div>
                                                    <div class="mt-2 text-muted">
                                                        * El envío a domicilio se desactiva para productos agendables, en su lugar, puede configurar el producto como cita a domicilio.
                                                    </div>
                                                </div>

                                                <div class="form-group col-12 pt-3">
                                                    <hr/>
                                                    <div>
                                                        <label class="mr-sm-2">Impuesto</label>
                                                        <multiselect v-model="impuestoSelected" :options="impuestos"
                                                                     track-by="id" label="name"></multiselect>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-12">
                                                    <hr/>
                                                    El cobro recurrente, permite que los clientes paguen un producto de
                                                    forma automática por períodos de tiempo que pueden variar entre:
                                                    mensual, semanal, quincenal o anual.
                                                </div>
                                                <div class="col-12 col-sm-12 mt-4">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                               v-model="recurrente" :checked="recurrente"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label"> Activar recurrencia</span>
                                                    </label>
                                                </div>
                                                <div class="col-12 mt-4" v-show="recurrente">
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="mr-sm-2">
                                                                Período de recurrencia
                                                                <i class="fa fa-question-circle"
                                                                    v-tooltip="'Puedes obtener mayor conocimiento sobre la configuración, haciendo clic en Más información.'"></i>
                                                            </label>
                                                            <select class="form-control" v-model="diasRecurrencia">
                                                                <option value="30">Mensual (30 días)</option>
                                                                <option value="60">Bimensual (60 días)</option>
                                                                <option value="90">Trimestral (90 días)</option>
                                                                <option value="180">Semestral (180 días)</option>
                                                                <option value="365">Anual (365 días)</option>
                                                                <!--<option value="15">Quincenal (15 días)</option>-->
                                                                <!--<option value="7">Semanal (7 días)</option>-->
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group">
                                                            <label class="mr-sm-2">
                                                                Configuración de cobro
                                                                <i class="fa fa-question-circle"
                                                                    v-tooltip="'Permite configurar el tiempo en el cual se realizará el cobro automático recurrente. Por defecto, las recurrencias se cobran en el mismo día en el cual se suscribió al cliente.'"></i>
                                                            </label>
                                                            <select class="form-control" v-model="cobroRecurrencia">
                                                                <option value="fecha_suscripcion">
                                                                    Mantener fecha de suscripción.
                                                                </option>
                                                                <option v-if="diasRecurrencia >= 15" value="inicio_periodo">
                                                                    Al inicio del período
                                                                </option>
                                                                <option v-if="diasRecurrencia >= 15" value="fin_periodo">Al
                                                                    final del período
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="mr-sm-2">
                                                                Cuotas de vencimiento
                                                                <i class="fa fa-question-circle"
                                                                    v-tooltip="'La recurrencia se vencerá al alcanzar un determinado número de cuotas'"></i>
                                                            </label>
                                                            <select class="form-control" v-model="cuotasVencimiento">
                                                                <option value="0">Sin vencimiento</option>
                                                                <option v-for="n in 12" :key="n+'cuota'" :value="n">{{n}} cuotas</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="mr-sm-2">Plantilla para recordatorio de cobro</label>
                                                            <input type="text" class="form-control" placeholder="Escribe aquí" v-model="tplRecordatorioCobro"/>
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="mr-sm-2">Días para envío de recordatorio (antes de cobro)</label>
                                                            <select class="form-control" v-model="diasRecordatorioCobro">
                                                                <option value="1">1 día antes de cobro</option>
                                                                <option value="5">5 días antes de cobro</option>
                                                                <option value="10">10 días antes de cobro</option>
                                                                <option value="15">15 días antes de cobro</option>
                                                                <option value="20">20 días antes de cobro</option>
                                                                <option value="25">25 días antes de cobro</option>
                                                                <option value="30">30 días antes de cobro</option>
                                                                <option value="60">60 días antes de cobro</option>
                                                            </select>
                                                        </div>
                                                        <div class="text-center mt-2">
                                                            <a class="text-primary" target="_blank"
                                                                href="https://abstrakt.bit.ai/rdc/jD7YsZruJcbVqyMP">Más
                                                                información</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card" v-if="id > 0">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Imágenes y multimedia
                                                <i class="fa fa-question-circle"
                                                   v-tooltip="'Carga imágenes y multimedia para tus productos, se recomiendan imágenes de 600x600px'"></i>
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="galleryContainer">
                                                        <div class="galleryItem" v-for="item in gallery" :key="item.id">
                                                            <div class="galleryItemImg"
                                                                 :style="'background-image: url('+item.imgUrl+')'">
                                                                <div class="overlay">
                                                                    <!--                                                                        <i class="fa fa-search mr-2"></i>-->
                                                                    <i class="fa fa-trash"
                                                                       @click="deleteImageFromGallery(item.id)"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12" v-if="Object.keys(gallery).length < 5">
                                                    <FileUploader url="products/img/upload"
                                                                  accepted-mime="image/jpg, image/jpeg, image/png, image/gif, application/pdf"
                                                                  remove-file-on-finish="1"
                                                                  :extra-data-form="imageUploadExtraDataForm"
                                                                  @fileSuccess="fileSuccess"></FileUploader>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card" v-if="id > 0">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Inventario y existencias
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row mb-4" v-if="!agendable">
                                                <div class="col-12 col-md-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="envio" :checked="envio"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">Habilitar envío</span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-md-6" v-if="envio">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="fragil" :checked="fragil"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">Frágil</span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="row" v-if="envio">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Peso en libras (lb)</label>
                                                        <input type="number" class="form-control" placeholder="Escribe aquí" v-model="pesoLb"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Ancho (cm)</label>
                                                        <input type="number" class="form-control" placeholder="Escribe aquí" v-model="anchoCm"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Alto (cm)</label>
                                                        <input type="number" class="form-control" placeholder="Escribe aquí" v-model="altoCm"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Longitud (cm)</label>
                                                        <input type="number" class="form-control" placeholder="Escribe aquí" v-model="longitudCm"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 form-group">
                                                    <label class="mr-sm-2">Localidad o bodega</label>
                                                    <select class="form-control" v-model="locacionId">
                                                        <option v-for="item in locaciones" :key="'loc' + item.id" :value="item.id">{{item.nombre}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row">
                                                <div class="col-12 form-group">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox"
                                                               v-model="manageStock" :checked="manageStock"/>
                                                        <div class="toggle-switch"></div>
                                                        <span
                                                            class="toggle-label">Habilitar manejo de existencias</span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-md-6 form-group">
                                                    <label class="mr-sm-2">Existencia</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí"
                                                           v-model="stock"/>
                                                </div>
                                                <div class="col-12 col-md-6 form-group">
                                                    <label class="mr-sm-2">Existencia mínima</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí"
                                                           v-model="stockMin"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card" v-if="id > 0">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Variaciones
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-2">
                                                Las variaciones permiten vender diferentes versiones de tus productos o servicios con distintos precios
                                            </div>
                                            <div class="row mt-3" v-if="showVariacionAdd">
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">SKU</label>
                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="variacion.sku"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Nombre</label>
                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="variacion.nombre"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Descripción</label>
                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="variacion.descripcion"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Precio</label>
                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="variacion.precio"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Precio Ofertado</label>
                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="variacion.precioOnSale"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <label class="mr-sm-2">Habilitar precio de oferta</label> <br>
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="variacion.onSale"
                                                            :checked="variacion.onSale"/>
                                                        <div class="toggle-switch"></div>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-12 mb-4">
                                                            <label class="toggle">
                                                                <input class="toggle-checkbox" type="checkbox"
                                                                    v-model="variacion.recurrente" :checked="variacion.recurrente"/>
                                                                <div class="toggle-switch"></div>
                                                                <span class="toggle-label"> Activar recurrencia</span>
                                                            </label>
                                                        </div>
                                                        <div v-show="variacion.recurrente" class="col-12 col-sm-4">
                                                            <label class="mr-sm-2">
                                                                Período de recurrencia
                                                                <i class="fa fa-question-circle"
                                                                    v-tooltip="'Puedes obtener mayor conocimiento sobre la configuración, haciendo clic en Más información.'"></i>
                                                            </label>
                                                            <div class="form-group">
                                                                <select class="form-control" v-model="variacion.diasRecurrencia">
                                                                    <option value="30">Mensual (30 días)</option>
                                                                    <option value="60">Bimensual (60 días)</option>
                                                                    <option value="90">Trimestral (90 días)</option>
                                                                    <option value="180">Semestral (180 días)</option>
                                                                    <option value="365">Anual (365 días)</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div v-show="variacion.recurrente" class="col-12 col-sm-4">
                                                            <label class="mr-sm-2">
                                                                Configuración de cobro
                                                                <i class="fa fa-question-circle"
                                                                    v-tooltip="'Permite configurar el tiempo en el cual se realizará el cobro automático recurrente. Por defecto, las recurrencias se cobran en el mismo día en el cual se suscribió al cliente.'"></i>
                                                            </label>
                                                            <div class="form-group">
                                                                <select class="form-control" v-model="variacion.cobroRecurrencia">
                                                                    <option value="fecha_suscripcion">Mantener fecha de
                                                                        suscripción.
                                                                    </option>
                                                                    <option v-if="variacion.diasRecurrencia >= 15" value="inicio_periodo">
                                                                        Al inicio del período
                                                                    </option>
                                                                    <option v-if="variacion.diasRecurrencia >= 15" value="fin_periodo">
                                                                        Al final del período
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div v-if="variacion.recurrente" class="col-12">
                                                            <div class="row">
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group mt-3">
                                                                        <label class="mr-sm-2">Plantilla para recordatorio de cobro</label>
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="variacion.tplRecordatorioCobro"/>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group mt-3">
                                                                        <label class="mr-sm-2">
                                                                            Cuotas de vencimiento
                                                                            <i class="fa fa-question-circle"
                                                                                v-tooltip="'La recurrencia se vencerá al alcanzar un determinado número de cuotas'"></i>
                                                                        </label>
                                                                        <select class="form-control" v-model="variacion.cuotasVencimiento">
                                                                            <option value="0">Sin vencimiento</option>
                                                                            <option v-for="n in 12" :key="n+'cuota'" :value="n">{{n}} cuotas</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group mt-3">
                                                                        <label class="mr-sm-2">Días para envío de recordatorio (antes de cobro)</label>
                                                                        <select class="form-control" v-model="variacion.diasRecordatorioCobro">
                                                                            <option value="1">1 día antes de cobro</option>
                                                                            <option value="5">5 días antes de cobro</option>
                                                                            <option value="10">10 días antes de cobro</option>
                                                                            <option value="15">15 días antes de cobro</option>
                                                                            <option value="20">20 días antes de cobro</option>
                                                                            <option value="25">25 días antes de cobro</option>
                                                                            <option value="30">30 días antes de cobro</option>
                                                                            <option value="60">60 días antes de cobro</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3 text-right">
                                                    <div class="btn btn-primary" @click="saveVariacion"><i class="fa fa-save mr-2"></i>Guardar</div>
                                                    <div class="btn btn-danger ml-3" @click="showVariacionAdd = false"><i class="fa fa-ban mr-2"></i>Cancelar</div>
                                                </div>
                                            </div>
                                            <div class="mt-2 text-right" v-if="!showVariacionAdd">
                                                <div class="btn btn-primary" @click="showVariacionAdd = true"><i class="fa fa-plus mr-2"></i>Agregar variación</div>
                                            </div>
                                            <div class="mt-3">
                                                <table class="table table-striped">
                                                    <tr>
                                                        <td class="font-weight-bold">SKU</td>
                                                        <td class="font-weight-bold">Nombre</td>
                                                        <td class="font-weight-bold">Descripción</td>
                                                        <td class="font-weight-bold">Precio</td>
                                                        <td class="font-weight-bold">Precio ofertado</td>
                                                        <td class="font-weight-bold">En oferta</td>
                                                        <td class="font-weight-bold">Recurrencia</td>
                                                        <td class="font-weight-bold">Días de recurrencia</td>
                                                        <td class="font-weight-bold">Dias de recordatorio</td>
                                                        <td class="font-weight-bold">Acciones</td>
                                                    </tr>
                                                    <tr v-for="itemVar in variaciones" :key="itemVar.id">
                                                        <td>{{itemVar.sku}}</td>
                                                        <td>{{itemVar.nombre}}</td>
                                                        <td>{{itemVar.descripcion}}</td>
                                                        <td>{{itemVar.precio}}</td>
                                                        <td>{{itemVar.precioOnSale}}</td>
                                                        <td>{{itemVar.onSale}}</td>
                                                        <td>
                                                            <span v-if="itemVar.recurrente">{{itemVar.cobroRecurrencia}}</span>
                                                            <span v-else>No</span>
                                                        </td>
                                                        <td>{{itemVar.diasRecurrencia}}</td>
                                                        <td>{{itemVar.diasRecordatorioCobro}}</td>
                                                        <td class="text-right">
                                                            <div @click="deleteVariacion(itemVar)" class="cursor-pointer text-danger"><i class="fa fa-trash"></i></div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="card" v-if="token !== ''">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Finalizar
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="text-center">
                                                        <button type="button" class="btn btn-primary mr-2" @click="preSave">
                                                            <i class="fa fa-save mr-2"></i> Guardar
                                                        </button>
                                                        <router-link to="/products">
                                                            <button type="button" class="btn btn-danger"><i class="fa fa-ban mr-2"></i>Cancelar</button>
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import Multiselect from 'vue-multiselect'
import FileUploader from 'src/components/files/FileUploader';
import TextEditor from 'src/components/TextEditor';
import Vue3TagsInput from 'vue3-tags-input';
import {mapGetters} from "vuex";

export default {
    components: {
        Layout,
        Multiselect,
        FileUploader,
        TextEditor,
        Vue3TagsInput
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        }),
    },
    data() {
        return {
            errors: [],
            enableMultiVendor: false,
            embedCodeSnippet: "",
            id: 0,
            nombre: '',
            slug: '',
            sku: '',
            descripcion: '',
            empresaId: '',
            categoriaId: 0,
            categoriaSelected: [],
            marcaSelected: [],
            personalSelected: [],
            impuestos: [],
            impuestoSelected: [],
            manufacturaId: '',
            proveedorId: '',
            precio: '',
            enableProduct: 0,
            onlineStoreId: '',
            erpId: '',
            showInStore: 0,
            nuevo: 0,
            destacado: 0,
            virtual: 0,
            agendable: 0,
            aDomicilio: 0,
            recurrente: 0,
            cobroRecurrencia: '',
            imageUrl: '',
            manageStock: 0,
            precioOnSale: 0,
            onSale: 0,
            precioConveniencia: 0,
            locacionId: 0,
            stockMin: 0,
            idProductoImpuesto: 0,
            stock: 0,
            payLink: '',
            payLinkQR: '',
            token: '',
            tiempoReserva: '',
            diasRecurrencia: 0,
            tplRecordatorioCobro: '',
            diasRecordatorioCobro: 0,
            cuotasVencimiento: 0,
            personal: [],
            asignacion:[],
            asignaciones:[],
            editorOption: {
            },
            imageUploadExtraDataForm: {
                productoId: 0,
            },
            gallery: {},
            categorias: [],
            marcas: [],
            locaciones: {},
            tags: [],
            tagsString: "",

            //envio
            envio: null,
            pesoLb: null,
            anchoCm: null,
            altoCm: null,
            longitudCm: null,
            fragil: 0,

            // multivendor
            showMultiVendor: 0,
            categoriasMv: [],
            categoriaMvSelected: [],
            marcasMv: [],
            marcasMvSelected: [],

            // Medico
            fichaMedica: '',

            // Variaciones
            showVariacionAdd: false,
            variacion: {
                id: 0,
                productoId: 0,
                sku: '',
                nombre: '',
                precio: '',
                precioOnSale: '',
                onSale: false,
                imageUrl: '',
                stockMin: '',
                stock: '',
                descripcion: '',
                recurrente: 0,
                diasRecurrencia: 0,
                cobroRecurrencia: '',
                token: '',
                tplRecordatorioCobro: '',
                diasRecordatorioCobro: 5,
                cuotasVencimiento: 0,
            },
            variaciones: {},

            // docs+
            formularioSelected: null,
            docsForms: {},

        }
    },
    watch: {
        /*categoriaSelected: function (val) {
            if (val) {
                this.categoriaId = val.id;
            }
        },*/
        impuestoSelected: function (val) {
            if (val) {
                this.idProductoImpuesto = val.id;

            }
        },
        onSale: function (val) {
            if (val) {
                if (!this.precioOnSale) {
                    this.precioOnSale = this.precio;
                }
            }
        },
        nombre: function (val) {
            if (!this.id) {
                this.slug = val;
            }
        },
        sku: function (val) {
            this.sku = val.toUpperCase();
        },
        slug: function (val) {
            this.slug = Tools.createSlug(val);
        },
    },
    mounted: function () {

        if (typeof this.whiteLabelInfo.multiVendor !== 'undefined' && this.whiteLabelInfo.multiVendor !== '') {
            this.enableMultiVendor = true;
        }

        const self = this;
        if (parseInt(this.$route.params.id) > 0) {
            self.loadProduct();
        }
        else {
            self.refreshTaxes(self.categoriaSelected);
        }

        self.getCategories();
        this.getDocsForms();

        // traigo las localidades
        API.send('GET', 'locaciones/all', {},
            function (response) {
                if (response.status) {
                    self.locaciones = response.data;
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })
    },
    methods: {
        loadProduct() {
            const self = this;
            self.imageUploadExtraDataForm = {
                productoId: this.$route.params.id,
            }
            API.send('GET', 'products/id/' + this.$route.params.id, {},
                function (response) {

                    if (response.status) {
                        const product = response.data[0];
                        self.id = product.id;
                        self.slug = product.slug;
                        self.nombre = product.nombre;
                        self.sku = product.sku;
                        self.descripcion = product.descripcion;
                        self.categoriaId = product.categoriaId;
                        self.precio = product.precio;
                        self.enableProduct = !!(product.status);
                        self.envio = !!(product.envio);
                        self.onlineStoreId = product.onlineStoreId;
                        self.erpId = product.erpId;
                        self.showInStore = !!(product.showInStore);
                        self.virtual = !!(product.virtual);
                        self.destacado = !!(product.destacado);
                        self.nuevo = !!(product.nuevo);
                        self.agendable = !!(product.agendable);
                        self.aDomicilio = !!(product.aDomicilio);
                        self.recurrente = !!(product.recurrente);
                        self.cobroRecurrencia = product.cobroRecurrencia;
                        self.manageStock = product.manageStock;
                        self.precioOnSale = product.precioOnSale;
                        self.onSale = !!(product.onSale);
                        self.precioConveniencia = !!(product.precioConveniencia);
                        self.locacionId = product.locacionId;
                        self.stockMin = product.stockMin;
                        self.stock = product.stock;
                        self.token = product.token;
                        self.payLinkQR = product.payLinkQR;
                        self.payLink = product.payLink;
                        self.diasRecurrencia = product.diasRecurrencia;
                        self.tplRecordatorioCobro = product.tplRecordatorioCobro;
                        self.diasRecordatorioCobro = product.diasRecordatorioCobro;
                        self.cuotasVencimiento = product.cuotasVencimiento;
                        self.tiempoReserva = product.tiempoReserva;
                        self.asignacion = product.asignacion;
                        self.pesoLb = product.pesoLb;
                        self.anchoCm = product.anchoCm;
                        self.altoCm = product.altoCm;
                        self.longitudCm = product.longitudCm;
                        self.fragil = !!(product.fragil);
                        self.showMultiVendor = !!(product.showMultiVendor);
                        self.fichaMedica = product.fichaMedica;
                        self.variaciones = product.variaciones;
                        self.formularioSelected = product.formularioId;

                        self.categoriaSelected = [];
                        Object.keys(product.categorias).map(function (key) {
                            self.categoriaSelected.push({nombre: product.categorias[key].nombre, id: product.categorias[key].id});
                        });

                        self.categoriaMvSelected = [];
                        Object.keys(product.categorias_mv).map(function (key) {
                            self.categoriaMvSelected.push({nombre: product.categorias_mv[key].nombre, id: product.categorias_mv[key].id});
                        });
                        self.marcaSelected = [];
                        Object.keys(product.marcas).map(function (key) {
                            self.marcaSelected.push({nombre: product.marcas[key].nombre, id: product.marcas[key].id});
                        });

                        self.categoriaMvSelected = [];
                        Object.keys(product.categorias_mv).map(function (key) {
                            self.categoriaMvSelected.push({nombre: product.categorias_mv[key].nombre, id: product.categorias_mv[key].id});
                        });
                        self.marcasMvSelected = [];
                        Object.keys(product.marcas_mv).map(function (key) {
                            self.marcasMvSelected.push({nombre: product.marcas_mv[key].nombre, id: product.marcas_mv[key].id});
                        });

                        self.refreshTaxes(product.idProductoImpuesto);

                        // tags
                        self.tagsString = product.etiquetas;
                        self.tags = product.etiquetas.split(',');
                    }
                    self.loadPersonal();
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })

            this.refreshMultimedia();
        },
        copyLink(copyvalue) {
            Tools.copyToClipboard(copyvalue);
        },
        getCategories() {
            const self = this;
            // obtengo lsa categorías
            API.send('GET', 'products/allCategoria/1', {},
                function (response) {
                    if (response.status) {
                        for (const category in response.data) {
                            self.categorias.push({nombre: response.data[category].nombre, id: response.data[category].id})
                        }
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
            API.send('GET', 'products/allMarca/1', {},
                function (response) {
                    if (response.status) {
                        for (const category in response.data) {
                            self.marcas.push({nombre: response.data[category].nombre, id: response.data[category].id})
                        }
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })

            if (self.enableMultiVendor) {
                // obtengo lsa categorías mv
                API.send('GET', 'products/allCategoriaMv/1', {},
                    function (response) {
                        if (response.status) {
                            for (const category in response.data) {
                                self.categoriasMv.push({nombre: response.data[category].nombre, id: response.data[category].id})
                            }
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
                API.send('GET', 'products/allMarcaMv/1', {},
                    function (response) {
                        if (response.status) {
                            for (const category in response.data) {
                                self.marcasMv.push({nombre: response.data[category].nombre, id: response.data[category].id})
                            }
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
        },
        refreshTaxes(categorySelected) {
            const self = this;
            // obtengo lsa categorías
            API.send('GET', 'products/allImpuestos/1', {},
                function (response) {
                    if (response.status) {
                        for (const category in response.data) {
                            self.impuestos.push({name: response.data[category].nombre, id: response.data[category].id})
                        }
                        self.impuestoSelected = self.impuestos.find(option => option.id === categorySelected);
                    }
                    /*console.log(self.impuestoSelected);*/
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        precioConvenienciaEnable() {
            const self = this;

            if (!self.precioConveniencia) {
                API.showConfirm('¿Esta seguro de activar?', 'El precio a conveniencia permite que los clientes seleccionen por sí mismos el monto a pagar por este producto, suele utilizarse en recepción de donaciones u otros.', function () {
                    self.precioConveniencia = 1;
                }, function () {
                    self.precioConveniencia = 0;
                })
            }
        },
        preSave(e) {
            this.errors = [];
            const self = this;

            if (!this.nombre) this.errors.push('El nombre del producto es requerido')
            if (!this.descripcion) this.errors.push('La descripción del producto es requerida');
            if (!this.precio) this.errors.push('Debe ingresar un precio para el producto');

            if (this.id > 0) {
                if (this.envio) {
                    // validación de envío vacío
                    if (!parseFloat(this.pesoLb) || !parseFloat(this.anchoCm) || !parseFloat(this.altoCm) || !parseFloat(this.longitudCm)) {
                        API.showErrorAlert('Error al configurar envío', 'Para habilitar el envío, debe ingresar el tamaño y peso del producto');
                        return false;
                    }
                }

                if (this.errors.length === 0) {

                    if (!this.envio && !this.agendable) {
                        API.showConfirm('Producto sin envío', "El producto se guardará sin configuración de envío, sus clientes no podrán seleccionar envío a domicilio para este producto. ¿Desea continuar?", function () {
                            self.save();
                        });
                    }
                    else {
                        self.save();
                    }
                }
                else {
                    API.showFormValidationErrors(this.errors);
                }
            }
            else {
                self.save();
            }

            e.preventDefault();
        },
        save() {
            const data = {
                id: this.id,
                nombre: this.nombre,
                slug: this.slug,
                sku: this.sku,
                descripcion: this.descripcion,
                precio: this.precio,
                status: this.enableProduct | 0,
                envio: this.envio | 0,
                onlineStoreId: this.onlineStoreId,
                erpId: this.erpId,
                showInStore: this.showInStore | 0,
                virtual: this.virtual | 0,
                destacado: this.destacado | 0,
                nuevo: this.nuevo | 0,
                agendable: this.agendable | 0,
                aDomicilio: this.aDomicilio | 0,
                tiempoReserva: this.tiempoReserva,
                asignacionPersonal: this.asignaciones,
                recurrente: this.recurrente | 0,
                cobroRecurrencia: this.cobroRecurrencia,
                manageStock: this.manageStock | 0,
                precioOnSale: this.precioOnSale,
                onSale: this.onSale | 0,
                precioConveniencia: this.precioConveniencia | 0,
                locacionId: this.locacionId | 0,
                stockMin: this.stockMin,
                stock: this.stock,
                diasRecurrencia: this.diasRecurrencia | 0,
                tplRecordatorioCobro: this.tplRecordatorioCobro,
                diasRecordatorioCobro: this.diasRecordatorioCobro | 0,
                cuotasVencimiento: this.cuotasVencimiento | 0,
                pesoLb: this.pesoLb | 0,
                anchoCm: this.anchoCm | 0,
                altoCm: this.altoCm | 0,
                longitudCm: this.longitudCm | 0,
                fragil: this.fragil | 0,
                showMultiVendor: this.showMultiVendor | 0,
                categorias: this.categoriaSelected,
                categoriasMv: this.categoriaMvSelected,
                marcas: this.marcaSelected,
                marcasMv: this.marcasMvSelected,
                idProductoImpuesto: this.idProductoImpuesto | 0,
                etiquetas: this.tagsString,
                fichaMedica: this.fichaMedica || '',
                formularioId: this.formularioSelected || 0,
            }
            if (this.id > 0) {
                //const self = this;
                API.send('PUT', 'products/edit/' + this.id, data, function (data) {
                        API.showSuccessAlert(data.msg);
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            }
            else {
                //const self = this;
                API.send('POST', 'products/new/', data, function (response) {
                        if (typeof response.status !== 'undefined') {

                            if (response.data.id) {
                                self.id = response.data.id;
                                self.token = response.data.token;
                                API.showSuccessAlert(response.msg);
                                window.location.href = '/products/edit/' + response.data.id;
                            } else {
                                API.showErrorNotify(response.msg);
                            }
                        } else {
                            API.showErrorNotify('Error al crear producto, por favor intente de nuevo');
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
        },
        refreshMultimedia() {
            const self = this;
            API.send('GET', 'products/img/gallery/' + this.$route.params.id, {},
                function (response) {
                    if (response.status) {
                        self.gallery = response.data;
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        deleteImageFromGallery(id) {
            const self = this;
            API.showConfirm('¿Esta seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'products/img/delete/' + id, {},
                    function (response) {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);

                            const newGallery = {};
                            for (let item in self.gallery) {
                                if (self.gallery[item].id !== id) {
                                    newGallery[item] = self.gallery[item];
                                }
                            }
                            self.gallery = newGallery;
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            })
        },
        fileSuccess(data) {
            this.gallery = Tools.pushToObject(this.gallery, data);
        },
        onEditorUpdate(value) {
            console.log(value);
            this.descripcion = value;
        },
        loadPersonal() {
            const self = this;
            self.personal = [];
            self.asignaciones = [];
            API.send('GET', 'personal/all/', {}, function (response) {
                    if (response.status) {

                        for (const [key, value] of Object.entries(response.data)) {
                            //console.log(key, value);
                            self.personal.push({name: value.nombres+' '+value.apellidos, id: value.id});
                            if(typeof (self.asignacion.find(option => option.personalId === value.id)) != 'undefined'){
                                self.asignaciones.push({name: value.nombres+' '+value.apellidos, id: value.id});
                            }
                        }
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        handleChangeTag(tags) {
            this.tags = tags;
            this.tagsString = tags.toString();
        },

        // Variaciones
        saveVariacion() {

            const self = this;
            self.variacion.productoId = this.id;

            //const self = this;
            API.send('POST', 'products/variations/edit', self.variacion, function (response) {
                self.showVariacionAdd = false;
                self.loadProduct();
                API.showSuccessNotify(response.msg);
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })
        },
        deleteVariacion(item) {

            const self = this;
            const data = {
                id: item.id
            };

            API.showConfirm('Antes de continuar', 'Si elimina una variación de producto no podrá recuperarla. Todas las suscripciones que contengan este producto se cancelarán y no se recibirán más pagos, ¿desea continuar?', function () {
                //const self = this;
                API.send('POST', 'products/variations/delete', data, function (response) {
                        API.showSuccessNotify(response.msg);
                        self.loadProduct();
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            })
        },
        getDocsForms() {
            const self = this;
            API.send('GET', 'formularios/all', self.variacion, function (response) {
                    self.docsForms = response.data;
                    //API.showSuccessNotify(response.msg);
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        }
    },
};
</script>
